import PropTypes from 'prop-types';
import { preventModalClick } from 'helpers';
import { regions } from 'enums/geolocationData';

import closeIcon from 'assets/img/matroskin/matroskin-close.svg';

const GeolocationSelect = ({ onChange, value, onClick, onClose }) => {
  return (
    <div
      className="geolocation-tab-content geolocation-tab-select"
      onClick={preventModalClick}
    >
      <h1 className="geolocation-tab-select-title">
        Выберите место, <br /> где вы находитесь?
      </h1>

      {regions.map((state, i) => (
        <a
          key={i}
          className={`geolocation-tab-select-option ${
            value === state.id ? 'geolocation-tab-select-option--active' : ''
          }`}
          onClick={() => onChange(state.id)}
        >
          {state.shortName}
        </a>
      ))}

      <button
        className="geolocation-tab-select-button"
        onClick={onClick}
        disabled={value === 0}
      >
        Подтвердить
      </button>

      <button className="geolocation-tab-close" onClick={onClose}>
        <img src={closeIcon} alt="close-icon" />
      </button>
    </div>
  );
};

GeolocationSelect.propTypes = {
  onChange: PropTypes.func,
  onClick: PropTypes.func,
  onClose: PropTypes.func,
  value: PropTypes.number,
};

export default GeolocationSelect;
