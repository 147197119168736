import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { preventModalClick } from 'helpers';

import closeIcon from 'assets/img/matroskin/matroskin-close.svg';

const InfoSpot = ({ img, video, playbackRate = 1, title, text, onClose }) => {
  useEffect(() => {
    const video = document.querySelector('.infospot-content video');
    if (!video) return;
    video.playbackRate = playbackRate;
  }, []);

  return (
    <div className="infospot" onClick={onClose}>
      <div className="infospot-content" onClick={preventModalClick}>
        {video ? (
          <video
            className="infospot-image"
            src={video}
            autoPlay
            muted
            loop
            playsInline
            alt="info-spot-video"
          />
        ) : (
          <img className="infospot-image" src={img} alt="info-spot-image" />
        )}
        <h3 className="infospot-title">{title}</h3>
        <p
          className="infospot-text"
          dangerouslySetInnerHTML={{ __html: text }}
        />

        <button className="infospot-close" onClick={onClose}>
          <img src={closeIcon} alt="close-icon" />
        </button>
      </div>
    </div>
  );
};

InfoSpot.propTypes = {
  playbackRate: PropTypes.number,
  onClose: PropTypes.func,
  title: PropTypes.string,
  video: PropTypes.string,
  text: PropTypes.string,
  img: PropTypes.string,
};

export default InfoSpot;
