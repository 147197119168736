import { makeAutoObservable } from 'mobx';
import { panoramaData } from 'enums/panoramaData';
import { matroskinStore, loadingStore } from 'store';
import {
  Viewer,
  CubePanorama,
  VideoPanorama,
  Infospot,
  DataImage,
  EmptyPanorama,
  ImagePanorama,
} from 'panolens';

const animationDuration = 500;

class PanoramaStore {
  emptyPanorama = new EmptyPanorama();

  currentPanoramaId = null;

  currentInfospotId = 'tutorial';

  container = null;

  panoramas = [];

  viewer = null;

  constructor() {
    makeAutoObservable(this);
  }

  get widget() {
    return this.viewer.widget;
  }

  get control() {
    return this.viewer.control;
  }

  get azimuthAngle() {
    return this.control.getAzimuthalAngle();
  }

  get polarAngle() {
    return this.control.getPolarAngle();
  }

  get currentPanorama() {
    return this.getPanoramaById(this.currentPanoramaId);
  }

  get videoPaused() {
    return this.controlElements.playPause.paused;
  }

  get controlElements() {
    return {
      main: this.widget.videoElement,
      playPause: this.widget.videoElement.controlButton,
      bar: this.widget.videoElement.seekBar,
      progress: this.widget.videoElement.seekBar.progressElement,
      circle: this.widget.videoElement.seekBar.progressElementControl,
    };
  }

  init(container) {
    this.container = container;

    this.viewer = this.createViewer();

    this.normalizeWidget();
    this.addWidgetListeners();

    panoramaData.forEach(this.createPanorama.bind(this));

    this.viewer.add(this.emptyPanorama, ...this.panoramas);

    setInterval(() => {
      if (!this.videoPanorama) return;
      if (!this.videoPanorama.videoElement.paused) return;
      this.videoPanorama.playVideo();
    }, 100);
  }

  // actions

  setCurrentPanoramaId(id) {
    this.currentPanoramaId = id;
  }

  setCurrentInfospotId(id) {
    this.currentInfospotId = id;
  }

  // viewer

  createViewer() {
    const viewer = new Viewer({
      container: this.container,
      controlButtons: ['video'],
      autoHideInfospot: false,
    });
    viewer.control.maxFov = 65;
    viewer.control.minFov = 65;
    viewer.setCameraFov(65);

    return viewer;
  }

  rotateTo(vector, speed = 1000) {
    this.viewer.tweenControlCenter(vector, speed);
  }

  openPanorama(id) {
    this.fetchController?.abort();

    this.setCurrentPanoramaId(id);
    const panorama = id ? this.getPanoramaById(id) : this.emptyPanorama;
    matroskinStore.setCurrentStep(panorama.matroskinStep || 'map');

    if (id) {
      this.rotateTo(panorama.initialLookAt);
    }

    if (!panorama.loaded) {
      loadingStore.show();
    }

    this.viewer.setPanorama(panorama);

    return panorama;
  }

  // panoramas

  async preloadVideo(src) {
    this.fetchController = new AbortController();
    const response = await fetch(src, { signal: this.fetchController.signal });
    const blob = await response.blob();
    return URL.createObjectURL(blob);
  }

  getPanoramaById(id) {
    return this.panoramas.find((panorama) => panorama.dataId === id);
  }

  createPanorama(data) {
    const panorama = data.sphere
      ? this.createSpherePanorama(data)
      : this.createImagePanorama(data);

    panorama.animationDuration = animationDuration;
    panorama.initialLookAt = data.initialLookAt;
    panorama.matroskinStep = data.matroskinStep;
    panorama.previewTime = data.previewTime;
    panorama.linkId = data.linkId;
    panorama.dataId = data.id;

    data.infospots.forEach(({ id, position, icon }) => {
      const distance = Math.sqrt(
        Math.pow(position.x, 2) + Math.pow(position.z, 2)
      );
      const scale = distance / 1300;
      const infospot = new Infospot(110 * scale, `${icon}?id=${id}`);
      infospot.position.copy(position);
      infospot.dataId = id;
      infospot.addEventListener('click', () => this.setCurrentInfospotId(id));

      panorama.add(infospot);
    });

    panorama.addEventListener('progress', ({ progress }) => {
      if (progress.loaded / progress.total === 1) {
        setTimeout(() => loadingStore.hide(), panorama.animationDuration);
      }
    });

    panorama.addEventListener('enter-fade-complete', async () => {
      if (data.video) {
        const videoData = await this.preloadVideo(data.video);

        this.videoPanorama = this.createVideoPanorama(videoData);
        this.viewer.add(this.videoPanorama);
        this.videoPanorama.playVideo();
        this.videoPanorama.onEnter();
        setTimeout(() => {
          this.videoPanorama.playVideo();
        }, this.videoPanorama.animationDuration);
      }
    });

    panorama.addEventListener('leave-start', () => {
      if (this.videoPanorama) {
        this.videoPanorama.onLeave();
      }
    });

    panorama.addEventListener('leave-complete', () => {
      if (this.videoPanorama) {
        this.viewer.remove(this.videoPanorama);
        this.videoPanorama.dispose();
        this.videoPanorama = null;
      }
    });

    this.panoramas.push(panorama);

    return panorama;
  }

  createImagePanorama(data) {
    return new CubePanorama(data.images);
  }

  createSpherePanorama(data) {
    return new ImagePanorama(data.sphere);
  }

  createVideoPanorama(src) {
    return new VideoPanorama(src, { autoplay: true, muted: true });
  }

  // widget

  normalizeWidget() {
    this.widget.mask.remove();
    this.widget.mainMenu.remove();
    this.controlElements.bar.remove();

    this.widget.barElement.classList.add('panorama-controls');
    this.widget.barElement.removeAttribute('style');

    this.controlElements.main.classList.add('panorama-controls-video');
    this.controlElements.playPause.classList.add('panorama-controls-toggle');
    this.controlElements.progress.classList.add('panorama-controls-progress');
    this.controlElements.circle.classList.add('panorama-controls-circle');

    Object.values(this.controlElements).forEach((element) => {
      element.removeAttribute('style');
    });
  }

  addWidgetListeners() {
    this.widget.addEventListener('video-control-hide', (e) => {
      this.controlElements.main.style.display = 'none';
    });

    this.widget.addEventListener('video-control-show', (e) => {
      this.controlElements.main.style.display = 'flex';
    });

    this.controlElements.playPause.update = function (paused) {
      this.paused = paused !== undefined ? paused : this.paused;
      if (this.paused) {
        this.classList.add('panorama-controls-toggle--paused');
        return;
      }
      this.classList.remove('panorama-controls-toggle--paused');
    };
  }

  destroy() {
    this.viewer.destroy();
    this.currentInfospotId = null;
    this.container = null;
    this.panoramas = [];
    this.viewer = null;
  }
}

export default new PanoramaStore();
