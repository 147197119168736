export const preventModalClick = (e) => {
  e.preventDefault();
  e.stopPropagation();
};

export const preventModalClickWithLink = (e) => {
  e.stopPropagation();
};

export const scrollToVideo = () => {
  const application = document.querySelector('.application');
  const videoEl = document.querySelector('.intro-video');
  const navbar = document.querySelector('.navbar');

  const top = videoEl.offsetTop - navbar.offsetHeight - 50;

  application.scrollTo({ top, behavior: 'smooth' });
};

export const scrollToCaption = () => {
  const application = document.querySelector('.application');
  const el = document.querySelector('.js-scroll-tatarstan-caption');
  const navbar = document.querySelector('.navbar');

  const top = el.offsetTop;

  application.scrollTo({ top, behavior: 'smooth' });
};
