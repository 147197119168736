import PropTypes from 'prop-types';
import { preventModalClick } from 'helpers';

import closeIcon from 'assets/img/matroskin/matroskin-close.svg';
import cow from 'assets/img/geolocation/cow.png';

const GeolocationResult = ({ img, count, onClick, onClose }) => {
  return (
    <div
      className={`geolocation-tab-content geolocation-tab-result ${
        count <= 0 ? 'geolocation-tab-result--empty' : ''
      }`}
      onClick={preventModalClick}
    >
      {img && (
        <img className="geolocation-tab-result-image" src={img} alt="region" />
      )}

      <h1 className="geolocation-tab-result-title">
        В регионе <br /> нет ферм
      </h1>

      <img className="geolocation-tab-result-cow" src={cow} alt="cow" />

      <p className="geolocation-tab-result-description">
        К сожалению, мы не нашли <br /> ферм рядом с вами. Возможно, <br /> их
        как раз строят.
      </p>

      <h3 className="geolocation-tab-result-subtitle">
        В нашем Простоквашино <br /> 230 ферм по всей стране!
      </h3>

      <p className="geolocation-tab-result-count">А в вашем регионе {count}*</p>

      <span className="geolocation-tab-result-date">
        *По состоянию на 18.07.2022
      </span>

      <button className="geolocation-tab-result-button" onClick={onClick}>
        Выйти
      </button>

      <button className="geolocation-tab-close" onClick={onClose}>
        <img src={closeIcon} alt="close-icon" />
      </button>
    </div>
  );
};

GeolocationResult.propTypes = {
  onClick: PropTypes.func,
  onClose: PropTypes.func,
  count: PropTypes.number,
  img: PropTypes.string,
};

export default GeolocationResult;
