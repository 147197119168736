import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { scrollToVideo } from 'helpers';
import { deviceMedia } from 'enums/device';
import { locations, mapSizes } from 'enums/backgroundData';
import { links } from 'config';

import backgroundMap from 'assets/img/background/background-map.jpg';
import backgroundMapTablet from 'assets/img/background/background-map--tablet.jpg';
import backgroundMapMobile from 'assets/img/background/background-map--mobile.jpg';
import scrollMobile from 'assets/img/scroll-mobile.svg';

const Background = ({ openTab, currentTab }) => {
  const [scale, setScale] = useState({ desktop: 1, tablet: 1, mobile: 1 });

  const calcScale = ({ width, height }) => {
    const scaleX = window.innerWidth / width;
    const scaleY = window.innerHeight / height;

    return Math.max(scaleX, scaleY);
  };

  const resizeApp = () => {
    const newScale = {
      desktop: calcScale(mapSizes.desktop),
      tablet: calcScale(mapSizes.tablet),
      mobile: calcScale(mapSizes.mobile),
    };

    setScale(newScale);
  };

  useEffect(() => {
    resizeApp();
    window.addEventListener('resize', resizeApp);
  }, []);

  return (
    <div
      className="background"
      style={{
        '--scale': scale.desktop,
        '--scale-tablet': scale.tablet,
        '--scale-mobile': scale.mobile,
      }}
    >
      <picture>
        <source srcSet={backgroundMapMobile} media={deviceMedia.mobile} />
        <source srcSet={backgroundMapTablet} media={deviceMedia.tablet} />
        <img
          className="background-map"
          src={backgroundMap}
          alt="background-map"
        />
      </picture>

      {locations.map((location, index) => (
        <picture key={index}>
          <source srcSet={location.img.mobile} media={deviceMedia.mobile} />
          <source srcSet={location.img.tablet} media={deviceMedia.tablet} />
          <div
            onClick={() => openTab(location.tab)}
            className={`background-location-area background-location-area-${location.tab}`}
          />
          <img
            className={`background-location background-${location.tab} ${
              location.tab === currentTab ? 'background-location--active' : ''
            }`}
            src={location.img.desktop}
            alt={`background-${location.tab}`}
          />
        </picture>
      ))}

      <div className="background-rights">
        <span>АО «Эйч энд Эн»</span>
        <a href={links.termsOfUse} target="_blank" rel="noreferrer">
          Пользовательское соглашение
        </a>
      </div>

      <button className="background-scroll-to-top" onClick={scrollToVideo}>
        <img src={scrollMobile} alt="scroll-button" />
      </button>
    </div>
  );
};

Background.propTypes = {
  currentTab: PropTypes.string,
  openTab: PropTypes.func,
};

export default Background;
