import { makeAutoObservable } from 'mobx';
import { regions } from 'enums/geolocationData';

localStorage.removeItem('regionId');

const API_URL = 'https://api.bigdatacloud.net/data/reverse-geocode-client';

class GeolocationStore {
  longitude = 37.618423;

  latitude = 55.751244;

  regionId = null;

  locationData = null;

  constructor() {
    makeAutoObservable(this);

    navigator.geolocation.getCurrentPosition(
      this.onSuccess.bind(this),
      () => {}
    );
  }

  get regionData() {
    const data = this.locationData?.localityInfo?.administrative.find(
      (info) => info.adminLevel === 4
    );

    const name = data?.name || 'Московская область';
    const region = this.regions.reduce((acc, { regions, id }) => {
      const value = regions.find((regionName) =>
        regionName.toLowerCase().includes(name.toLowerCase())
      );

      if (value) {
        return { name: value, id };
      }
      return acc;
    }, null);

    return region;
  }

  get regions() {
    return regions;
  }

  get farmsCount() {
    const data = this.getRegionById(this.regionId);

    return data?.farmsCount || 0;
  }

  get farmsImg() {
    const data = this.getRegionById(this.regionId);

    return data?.img || null;
  }

  getRegionById(id) {
    return this.regions.find((region) => region.id === id);
  }

  getRegionByName(name) {
    return this.regions.find((region) => region.name === name);
  }

  setRegionId(id) {
    this.regionId = id;
  }

  setData(data) {
    this.locationData = data;
  }

  async onSuccess({ coords }) {
    this.longitude = coords.longitude;
    this.latitude = coords.latitude;
    return await this.getLocationData();
  }

  async getLocationData() {
    const url = `${API_URL}?localityLanguage=ru&latitude=${this.latitude}&longitude=${this.longitude}`;
    const response = await fetch(url);

    if (!response.ok) return;

    const data = await response.json();
    this.setData(data);

    return data;
  }
}

export default new GeolocationStore();
