import PropTypes from 'prop-types';
import { preventModalClick } from 'helpers';
import { useEffect, useState } from 'react';
import { allQuestions } from 'enums/academyGameData';
import AcademyResult from 'components/Academy/AcademyResult';

import closeIcon from 'assets/img/matroskin/matroskin-close.svg';
import preview from 'assets/img/matroskin/quiz.png';

const AcademyGame = ({ onClose }) => {
  const questionsCount = 5;

  const [correctAnswersCount, setCorrectAnswersCount] = useState(0);
  const [questionIndex, setQuestionIndex] = useState(0);
  const [questions, setQuestions] = useState([]);
  const [chosenAnswerIndex, setChosenAnswerIndex] = useState(null);
  const [isAnswerSubmitted, setAnswerSubmittedState] = useState(false);

  const currentQuestion = questions[questionIndex];

  const restart = () => {
    setCorrectAnswersCount(0);
    setQuestionIndex(0);
    setChosenAnswerIndex(null);
    setAnswerSubmittedState(false);

    const questionsList = [...allQuestions]
      .sort(() => 0.5 - Math.random())
      .slice(0, questionsCount);

    setQuestions(questionsList);
  };

  const checkAnswer = () => {
    setAnswerSubmittedState(true);

    if (!currentQuestion.answers[chosenAnswerIndex].correct) return;
    setCorrectAnswersCount(correctAnswersCount + 1);
  };

  const openNextQuestion = () => {
    setQuestionIndex(questionIndex + 1);
    setAnswerSubmittedState(false);
    setChosenAnswerIndex(null);
  };

  const getAnswerClassName = (answer, index) => {
    let className = '';

    if (chosenAnswerIndex === index) className += 'chosen';

    if (!isAnswerSubmitted) return className;

    if (answer.correct) {
      className += ' correct';
    } else {
      className += ' incorrect';
    }

    return className;
  };

  useEffect(restart, []);

  if (questionIndex === questionsCount) {
    return (
      <AcademyResult
        onClick={restart}
        onClose={onClose}
        correct={correctAnswersCount}
        count={questionsCount}
      />
    );
  }

  if (!currentQuestion) return null;

  return (
    <div
      className="academy-tab-content academy-tab-game"
      onClick={preventModalClick}
    >
      <img
        className="academy-tab-game-preview"
        src={isAnswerSubmitted ? currentQuestion.preview : preview}
        alt="question preview"
      />
      <h1 className="academy-tab-game-count">
        Вопрос {questionIndex + 1}/{questionsCount}
      </h1>
      <p
        className="academy-tab-game-text"
        dangerouslySetInnerHTML={{
          __html: isAnswerSubmitted
            ? currentQuestion.description
            : currentQuestion.question,
        }}
      />
      <ul className="academy-tab-game-answers">
        {currentQuestion.answers.map((answer, index) => (
          <li key={index}>
            <button
              disabled={isAnswerSubmitted}
              className={getAnswerClassName(answer, index)}
              onClick={() => setChosenAnswerIndex(index)}
              dangerouslySetInnerHTML={{ __html: answer.title }}
            />
          </li>
        ))}
      </ul>

      {!isAnswerSubmitted && (
        <button
          className="academy-tab-button"
          onClick={checkAnswer}
          disabled={chosenAnswerIndex === null}
        >
          Ответить
        </button>
      )}
      {isAnswerSubmitted && (
        <button
          className="academy-tab-button academy-tab-button--big"
          onClick={openNextQuestion}
        >
          Следующий вопрос
        </button>
      )}

      <button className="academy-tab-close" onClick={onClose}>
        <img src={closeIcon} alt="close-icon" />
      </button>
    </div>
  );
};

AcademyGame.propTypes = {
  onClose: PropTypes.func,
};

export default AcademyGame;
