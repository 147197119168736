import { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { geolocationStore as store, matroskinStore } from 'store';
import GeolocationConfirm from 'components/Geolocation/GeolocationConfirm';
import GeolocationSelect from 'components/Geolocation/GeolocationSelect';
import GeolocationResult from 'components/Geolocation/GeolocationResult';
import InfoModal from 'components/InfoModals/InfoModal';

import image from 'assets/img/info-modals/geolocation.jpg';

const GeolocationTab = observer(({ onClose }) => {
  const [selectedRegionId, setSelectedRegionId] = useState(1);
  const [currentStep, setCurrentStep] = useState(1);

  useEffect(() => {
    matroskinStore.setCurrentStep('geolocation-1');
    store.getLocationData();
  }, []);

  useEffect(() => {
    if (currentStep >= 2) {
      matroskinStore.show();
    }
    if (currentStep === 3) {
      matroskinStore.setCurrentStep('geolocation-2');
    } else {
      matroskinStore.setCurrentStep('geolocation-1');
    }
  }, [currentStep]);

  const acceptOption = (id = store.regions[0].id) => {
    setCurrentStep(0);

    store.setRegionId(id);

    setCurrentStep(4);
  };

  const declineOption = () => {
    setCurrentStep(3);
  };

  const submitOption = () => {
    store.setRegionId(selectedRegionId);
    setCurrentStep(4);
  };

  return (
    <div className="geolocation-tab" onClick={onClose}>
      {currentStep === 1 && (
        <InfoModal
          img={image}
          title="Наши фермы"
          text={`В Простоквашино знают, как важно, <br /> чтобы в каждой семье было только качественное молоко. А свежее оно потому, что приезжает с 230 ферм* по всей стране. Так и получается, что Простоквашино всегда рядом`}
          small="*по состоянию на 18.07.2022"
          onClick={() => setCurrentStep(2)}
          onClose={onClose}
        />
      )}

      {currentStep === 2 && (
        <GeolocationConfirm
          onClose={onClose}
          regionData={store.regionData}
          decline={declineOption}
          accept={acceptOption}
        />
      )}

      {currentStep === 3 && (
        <GeolocationSelect
          onChange={setSelectedRegionId}
          value={selectedRegionId}
          onClick={submitOption}
          onClose={onClose}
        />
      )}

      {currentStep === 4 && (
        <GeolocationResult
          img={store.farmsImg}
          count={store.farmsCount}
          onClose={onClose}
          onClick={onClose}
        />
      )}
    </div>
  );
});

GeolocationTab.propTypes = {
  onClose: PropTypes.func,
};

export default GeolocationTab;
