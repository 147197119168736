const QualityLineDesktop = () => {
  return (
    <svg
      viewBox="0 0 639 1153"
      className="quality-tab-line--desktop"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
    >
      <path
        d="M55 16C55.0001 79.3634 138.504 94.1352 214.071 99.5775C289.638 105.02 369 99.5775 369 154C369 208.423 659.5 240 623 325C586.5 410 506.5 386.5 433 375C359.5 363.5 295.5 298 178 397.5C60.4997 497 15.9999 642.637 16 706C16.0001 769.363 62.933 805.058 138.5 810.5C214.067 815.942 273 777.5 342 830.5C411 883.5 646.809 978.414 614 1064C581.191 1149.59 509.281 1125.92 443.213 1114.34C377.146 1102.77 319.618 1036.81 214 1137"
        stroke="#FFFFFF"
        strokeWidth="6"
        strokeLinejoin="round"
        strokeDasharray="2770"
        strokeDashoffset="2770"
        data-reversed="0"
      />
      <path
        d="M55 16C55.0001 79.3634 138.504 94.1352 214.071 99.5775C289.638 105.02 369 99.5775 369 154C369 208.423 659.5 240 623 325C586.5 410 506.5 386.5 433 375C359.5 363.5 295.5 298 178 397.5C60.4997 497 15.9999 642.637 16 706C16.0001 769.363 62.933 805.058 138.5 810.5C214.067 815.942 273 777.5 342 830.5C411 883.5 646.809 978.414 614 1064C581.191 1149.59 509.281 1125.92 443.213 1114.34C377.146 1102.77 319.618 1036.81 214 1137"
        stroke="#BFE0FA"
        strokeWidth="8"
        strokeLinejoin="round"
        strokeDasharray="12 12"
      />
      <path
        d="M342 830.5C411 883.5 646.809 978.414 614 1064"
        stroke="#BFE0FA"
        strokeWidth="8"
        strokeLinejoin="round"
      />
      <path
        d="M330 110.908C353.64 117.702 369 130.058 369 154C369 208.423 659.5 240 623 325"
        stroke="#BFE0FA"
        strokeWidth="8"
        strokeLinejoin="round"
      />
      <path
        d="M16 706C15.9999 642.637 60.4997 497 178 397.5"
        stroke="#BFE0FA"
        strokeWidth="8"
        strokeLinejoin="round"
      />
      <circle
        cx="219"
        cy="1134"
        r="15"
        fill="#FFFFFF"
        data-opacity-start="0.90"
        data-opacity-end="1"
        data-image-index="4"
        data-property="--desktop-value"
      />
      <circle
        cx="614"
        cy="1063"
        r="15"
        fill="#FFFFFF"
        data-opacity-start="0.80"
        data-opacity-end="0.90"
        data-property="--desktop-value"
      />
      <circle
        cx="345"
        cy="830"
        r="15"
        fill="#FFFFFF"
        data-opacity-start="0.72"
        data-opacity-end="0.82"
        data-image-index="3"
        data-property="--desktop-value"
      />
      <circle
        cx="15"
        cy="709"
        r="15"
        fill="#FFFFFF"
        data-opacity-start="0.52"
        data-opacity-end="0.62"
        data-property="--desktop-value"
      />
      <circle
        cx="175"
        cy="405"
        r="15"
        fill="#FFFFFF"
        data-opacity-start="0.45"
        data-opacity-end="0.55"
        data-image-index="2"
        data-property="--desktop-value"
      />
      <circle
        cx="625"
        cy="321"
        r="15"
        fill="#FFFFFF"
        data-opacity-start="0.23"
        data-opacity-end="0.33"
        data-property="--desktop-value"
      />
      <circle
        cx="335"
        cy="112"
        r="15"
        fill="#FFFFFF"
        data-opacity-start="0.14"
        data-opacity-end="0.24"
        data-image-index="1"
        data-property="--desktop-value"
      />
      <circle
        cx="56"
        cy="15"
        r="15"
        fill="#FFFFFF"
        data-opacity-start="0"
        data-opacity-end="0"
        data-image-index="0"
        data-property="--desktop-value"
      />
    </svg>
  );
};

export default QualityLineDesktop;
