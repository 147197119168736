import { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { matroskinStore } from 'store';
import InfoModal from 'components/InfoModals/InfoModal';
import AcademyTutorial from 'components/Academy/AcademyTutorial';
import AcademyGame from 'components/Academy/AcademyGame';
import { setDataLayer } from 'gtm';

import image from 'assets/img/info-modals/academy.jpg';

const AcademyTab = observer(({ onClose }) => {
  const [currentStep, setCurrentStep] = useState(1);

  useEffect(() => {
    matroskinStore.setCurrentStep('academy');
  }, []);

  useEffect(() => {
    if (currentStep === 2) {
      setDataLayer({
        event: 'GAEvent',
        eventCategory: 'click',
        eventAction: 'button',
        eventLabel: 'more-milk-academy',
      });
    }
  }, [currentStep]);

  useEffect(() => {
    if (currentStep === 3) {
      matroskinStore.show();
    }
  }, [currentStep]);

  return (
    <div className="academy-tab" onClick={onClose}>
      {currentStep === 1 && (
        <InfoModal
          img={image}
          title="Молочная академия"
          text="Мы и о фермерах позаботились: организовали целую «Молочную Бизнес Академию». Там их учим лучшим практикам молочного дела. Чтобы вы получали ещё более качественное и вкусное молоко."
          onClick={() => setCurrentStep(2)}
          onClose={onClose}
        />
      )}

      {currentStep === 2 && (
        <AcademyTutorial onClick={() => setCurrentStep(3)} onClose={onClose} />
      )}

      {currentStep === 3 && <AcademyGame onClose={onClose} />}
    </div>
  );
});

AcademyTab.propTypes = {
  onClose: PropTypes.func,
};

export default AcademyTab;
