import TagManager from 'react-gtm-module';
export function initGTM() {
  const tagManagerArgs = {
    gtmId: 'GTM-M7S358X',
  };
  TagManager.initialize(tagManagerArgs);
}

export function setDataLayer(dataLayer = {}) {
  const tagManagerArgs = {
    dataLayer,
  };
  TagManager.dataLayer(tagManagerArgs);
}
