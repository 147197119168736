import PropTypes from 'prop-types';
import { preventModalClick } from 'helpers';

import closeIcon from 'assets/img/matroskin/matroskin-close.svg';

const InfoModal = ({
  img,
  title,
  text,
  description,
  small,
  onClose,
  onClick,
  blur = false,
}) => {
  return (
    <div
      className={`info-modal ${blur ? 'info-modal--blur' : ''}`}
      onClick={onClose}
    >
      <div className="info-modal-content" onClick={preventModalClick}>
        <img className="info-modal-image" src={img} alt="info-modal-image" />
        <h1 className="info-modal-title">{title}</h1>

        <p
          className="info-modal-text"
          dangerouslySetInnerHTML={{ __html: text }}
        />

        {description && (
          <span
            className="info-modal-description"
            dangerouslySetInnerHTML={{ __html: description }}
          />
        )}
        {small && <span className="info-modal-small">{small}</span>}

        <button className="info-modal-button" onClick={onClick}>
          Подробнее
        </button>

        <button className="info-modal-close" onClick={onClose}>
          <img src={closeIcon} alt="close-icon" />
        </button>
      </div>
    </div>
  );
};

InfoModal.propTypes = {
  description: PropTypes.string,
  small: PropTypes.string,
  title: PropTypes.string,
  onClose: PropTypes.func,
  onClick: PropTypes.func,
  text: PropTypes.string,
  img: PropTypes.string,
  blur: PropTypes.bool,
};

export default InfoModal;
