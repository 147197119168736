import { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { matroskinStore } from 'store';
import { preventModalClick } from 'helpers';
import { workers } from 'enums/workersData';

import closeIcon from 'assets/img/matroskin/matroskin-close.svg';
import arrowIcon from 'assets/img/icon-arrow-right.svg';

const WorkersTab = observer(({ onClose }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    matroskinStore.setCurrentStep('workers');
    matroskinStore.show();
  }, []);

  const openNext = () => {
    const nextIndex = currentIndex + 1;

    if (!workers[nextIndex]) {
      setCurrentIndex(0);
      return;
    }

    setCurrentIndex(nextIndex);
  };

  const openPrev = () => {
    const nextIndex = currentIndex - 1;

    if (!workers[nextIndex]) {
      setCurrentIndex(workers.length - 1);
      return;
    }

    setCurrentIndex(nextIndex);
  };

  const currentWorker = workers[currentIndex];

  return (
    <div className="workers-tab" onClick={onClose}>
      <div className="workers-tab-content" onClick={preventModalClick}>
        <img
          key={currentWorker.img}
          className="workers-tab-image"
          src={currentWorker.img}
          alt="worker"
        />

        <p
          className="workers-tab-text"
          dangerouslySetInnerHTML={{ __html: currentWorker.text }}
        />

        <div className="workers-tab-buttons">
          <button
            className="workers-tab-arrow workers-tab-arrow--prev"
            onClick={openPrev}
          >
            <img src={arrowIcon} alt="arrow-icon" />
          </button>

          <button
            className="workers-tab-arrow workers-tab-arrow--next"
            onClick={openNext}
          >
            <img src={arrowIcon} alt="arrow-icon" />
          </button>
        </div>

        <button className="workers-tab-close" onClick={onClose}>
          <img src={closeIcon} alt="close-icon" />
        </button>
      </div>
    </div>
  );
});

WorkersTab.propTypes = {
  onClose: PropTypes.func,
};

export default WorkersTab;
