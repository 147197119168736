import { Vector3 } from 'three';

import cameraIcon from 'assets/img/icon-camera.png';
import videoIcon from 'assets/img/icon-video.png';
import chatIcon from 'assets/img/icon-chat.png';

import right from 'assets/panoramas/panorama-1/right.jpeg';
import left from 'assets/panoramas/panorama-1/left.jpeg';
import top from 'assets/panoramas/panorama-1/up.jpeg';
import bottom from 'assets/panoramas/panorama-1/down.jpeg';
import front from 'assets/panoramas/panorama-1/front.jpeg';
import back from 'assets/panoramas/panorama-1/back.jpeg';

import sphere2 from 'assets/panoramas/panorama-2/sphere.mp4';
import preview2 from 'assets/panoramas/panorama-2/sphere.jpg';

import sphere3 from 'assets/panoramas/panorama-3/sphere.mp4';
import preview3 from 'assets/panoramas/panorama-3/sphere.jpg';

export const panoramaData = [
  {
    id: 'health',
    images: [right, left, top, bottom, front, back],
    sphere: null,
    video: null,
    initialLookAt: new Vector3(110, -100, 500),
    infospots: [
      {
        id: 'infospot-1',
        position: new Vector3(-80, -120, 500),
        icon: cameraIcon,
      },
      {
        id: 'infospot-2',
        position: new Vector3(310, -150, 500),
        icon: cameraIcon,
      },
      {
        id: 'infospot-3',
        position: new Vector3(130, -150, 300),
        icon: chatIcon,
      },
    ],
    matroskinStep: 'health',
    previewTime: 0,
  },
  {
    id: 'food',
    linkId: 'food-1',
    images: [],
    sphere: preview2,
    video: sphere2,
    initialLookAt: new Vector3(350, -50, 50),
    infospots: [
      {
        id: 'infospot-4',
        position: new Vector3(350, -70, -30),
        icon: cameraIcon,
      },
      {
        id: 'infospot-5',
        position: new Vector3(110, -7, 50),
        icon: videoIcon,
      },
    ],
    matroskinStep: 'food',
    previewTime: 0.1,
  },
  {
    id: 'food-1',
    linkId: 'food',
    images: [],
    sphere: preview3,
    video: sphere3,
    initialLookAt: new Vector3(500, -50, 330),
    infospots: [
      {
        id: 'infospot-6',
        position: new Vector3(500, -250, 300),
        icon: chatIcon,
      },
    ],
    matroskinStep: 'food-1',
    previewTime: 0.1,
  },
];
