import PropTypes from 'prop-types';
import { preventModalClick } from 'helpers';

import closeIcon from 'assets/img/matroskin/matroskin-close.svg';
import present from 'assets/img/present.png';

const AcademyTutorial = ({ onClick, onClose }) => {
  return (
    <div
      className="academy-tab-content academy-tab-tutorial"
      onClick={preventModalClick}
    >
      <h1 className="academy-tab-tutorial-title">Проверьте себя</h1>
      <img className="academy-tab-tutorial-image" src={present} alt="present" />
      <p className="academy-tab-tutorial-description">
        Сдайте экзамен <br /> в Молочной Академии
      </p>
      <span className="academy-tab-tutorial-subdescription">
        и получите приз
      </span>
      <button className="academy-tab-button" onClick={onClick}>
        Начать
      </button>
      <button className="academy-tab-close" onClick={onClose}>
        <img src={closeIcon} alt="close-icon" />
      </button>
    </div>
  );
};

AcademyTutorial.propTypes = {
  onClick: PropTypes.func,
  onClose: PropTypes.func,
};

export default AcademyTutorial;
