import PropTypes from 'prop-types';
import { preventModalClick } from 'helpers';
import MatroskinMessage from 'components/MatroskinMessage';

import closeIcon from 'assets/img/matroskin/matroskin-close.svg';

const DialogModal = ({ title, messages, onClose }) => {
  return (
    <div className="dialog-modal" onClick={onClose}>
      <div className="dialog-modal-content" onClick={preventModalClick}>
        <h2 className="dialog-modal-title">{title}</h2>

        <div className="dialog-modal-messages">
          {messages.map((message, i) => (
            <div className="dialog-modal-message" key={i}>
              <div className="dialog-modal-message-image">
                <img src={message.img} alt="message-image" />
              </div>
              <MatroskinMessage
                active={true}
                image={null}
                text={message.text}
              />
            </div>
          ))}
        </div>

        <button className="infospot-close" onClick={onClose}>
          <img src={closeIcon} alt="close-icon" />
        </button>
      </div>
    </div>
  );
};

DialogModal.propTypes = {
  messages: PropTypes.array,
  onClose: PropTypes.func,
  title: PropTypes.string,
};

export default DialogModal;
