import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { panoramaStore } from 'store';
import { links } from 'config';

import logo from 'assets/img/navbar-logo.png';

const Navbar = observer(({ currentTab, openTab }) => {
  const disabled = panoramaStore.loading;
  const [menuState, setMenuState] = useState(false);

  const navbarLinks = [
    { tab: 'health', title: 'Здоровье' },
    { tab: 'food', title: 'Питание' },
    { tab: 'quality', title: 'Качество' },
    { tab: 'care', title: 'Уход' },
    { tab: 'academy', title: 'Молочная академия' },
  ];

  const openMenu = () => {
    setMenuState(true);
  };

  const closeMenu = () => {
    setMenuState(false);
  };

  const onClick = (tab) => {
    closeMenu();
    openTab(tab);
  };

  useEffect(() => {
    window.addEventListener('resize', closeMenu);
  }, []);

  return (
    <nav className={`navbar ${menuState ? 'navbar-opacity' : ''}`}>
      {navbarLinks.map((link, i) => (
        <button
          key={i}
          className={`navbar-item ${
            currentTab === link.tab ? 'navbar-item--active' : ''
          }`}
          onClick={() => onClick(link.tab)}
          disabled={disabled}
        >
          {link.title}
        </button>
      ))}

      <a
        className="navbar-logo"
        href={links.prostokvashino}
        target="_blank"
        rel="noreferrer"
      >
        <img src={logo} alt="logo" />
      </a>

      <button
        className={`navbar-menu-button
        ${menuState ? 'navbar-menu--open' : ''}`}
        onClick={openMenu}
      >
        <div className="navbar-menu-button--line line"></div>
        <div className="navbar-menu-button--line line"></div>
        <div className="navbar-menu-button--line line"></div>
      </button>
      <button
        className={`navbar-close-button
        ${menuState ? 'navbar--open' : ''}`}
        onClick={closeMenu}
      >
        <div className="navbar-close-button--line line"></div>
        <div className="navbar-close-button--line line"></div>
      </button>

      {menuState && <div className="navbar-overlay" onClick={closeMenu} />}
    </nav>
  );
});

Navbar.propTypes = {
  currentTab: PropTypes.string,
  openTab: PropTypes.func,
};

export default Navbar;
