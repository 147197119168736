export const regionsData = [
  {
    title: 'Татарстане',
    textDesktop:
      'Для Простоквашино мы берем молоко с лучших* ферм Татарстана! Заботимся о продуктах на всех этапах: бережем здоровье буренок, следим за качеством на заводе в Казани и быстро доставляем до полок.',
    textMobile: 'Для Простоквашино мы берем молоко с лучших* ферм Татарстана!',
    copyright:
      '*Лучшие по оценке АО «Эйч энд Эн» фермы-партнеры на территории Республики Татарстан, отобранные по результатам аудита по качеству молока, по состоянию на 25.04.2024 г.',
    url: '/tatarstan/',
    caption: 'Казани',
  },
  {
    title: 'Сибири',
    textDesktop:
      'Для Простоквашино мы берем молоко с лучших* ферм Сибири! Заботимся о продуктах на всех этапах: бережем здоровье буренок, следим за качеством на заводе в Сибири и быстро доставляем до полок.',
    textMobile: 'Для Простоквашино мы берем молоко с лучших* ферм Сибири!',
    copyright:
      '*Лучшие по оценке АО «Эйч энд Эн» фермы-партнеры на территории Сибири, отобранные по результатам аудита по качеству молока, по состоянию на 12.06.24 г.',
    url: '/siberia/',
    caption: 'Сибири',
  },
];
