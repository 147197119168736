import PropTypes from 'prop-types';
import YouTube from 'react-youtube';
import { useEffect, useState } from 'react';
import { preventModalClick, scrollToVideo, scrollToCaption } from 'helpers';
import MatroskinMessage from 'components/MatroskinMessage';
import { youtubeVideoID } from 'config';

import matroskin from 'assets/img/matroskin/matroskin-with-milk.png';
import scrollMobile from 'assets/img/scroll-mobile.svg';
import closeIcon from 'assets/img/matroskin/matroskin-close.svg';
import tatarstanBottleOne from 'assets/img/intro-tatarstan-bottle-1.png';
import tatarstanBottleTwo from 'assets/img/intro-tatarstan-bottle-2.png';
import tatarstanIcon from 'assets/img/intro-tatarstan-icon.png';
import matroskinClose from 'assets/img/matroskin/matroskin-close.svg';

const Intro = ({
  isMobile,
  currentTab,
  onStartVideo,
  onClose,
  showMap,
  hideElements = false,
  region = false,
}) => {
  const [playerTarget, setPlayerTarget] = useState(false);

  useEffect(() => {
    if (currentTab === 'video') return;
    if (!playerTarget) return;

    playerTarget.pauseVideo();
  }, [currentTab]);

  const handleClick = (e) => {
    if (region && e.target.classList.contains('intro')) {
      showMap();
      return;
    }

    if (!hideElements || isMobile) return;
    onClose();
  };

  const onPlay = () => {
    onStartVideo();
  };

  const onReady = ({ target }) => {
    setPlayerTarget(target);
  };

  return (
    <div
      className={`intro ${hideElements ? 'intro--hidden-elements' : ''}`}
      onClick={handleClick}
    >
      {region ? (
        <div className="intro-content-tatarstan">
          <h1>
            Производим <span className="desktop">Простоквашино</span>{' '}
            <span className="color">в {region.title}</span> с любовью
          </h1>
          <p className="desktop">{region.textDesktop}</p>
          <p className="mobile">{region.textMobile}</p>
          <div className="intro-content-tatarstan-caption">
            <span className="desktop">
              Убедитесь в этом на онлайн-экскурсии по ферме Простоквашино и
              выиграйте приз в викторине!
            </span>
            <span className="mobile">
              Узнайте, как мы заботимся о качестве
              <br />
              на онлайн-ферме, и выиграйте приз
              <br />в викторине!
            </span>
          </div>

          <div className="intro-content-tatarstan-caption-mobile js-scroll-tatarstan-caption">
            Заботимся о продуктах на всех этапах:
            <br />
            бережем здоровье буренок, следим <br />
            за качеством на заводе в {region.caption}
            <br />и быстро доставляем до полок.
            <img src={tatarstanBottleTwo} alt="" />
            <img src={tatarstanIcon} alt="" />
          </div>

          <img
            className="intro-content-tatarstan-bottle-1"
            src={tatarstanBottleOne}
            alt=""
          />
          <img
            className="intro-content-tatarstan-bottle-2"
            src={tatarstanBottleTwo}
            alt=""
          />
          <img
            className="intro-content-tatarstan-icon"
            src={tatarstanIcon}
            alt=""
          />
          <button className="intro-content-tatarstan-button" onClick={onClose}>
            Далее
          </button>
          <button className="intro-content-tatarstan-close" onClick={showMap}>
            <img src={matroskinClose} />
          </button>
          <button className="intro-scroll" onClick={scrollToCaption}>
            <img src={scrollMobile} alt="scroll-button" />
          </button>
          <div className="intro-content-tatarstan-info">{region.copyright}</div>
        </div>
      ) : (
        <div className="intro-content">
          <h1>
            <span>Добро пожаловать</span> <br /> На ферму Простоквашино!
          </h1>

          <div className="intro-video" onClick={preventModalClick}>
            <div className="intro-video-badge">
              Смотрите, как <br /> у нас на фермах все устроено
            </div>
            <YouTube
              className="intro-video-youtube"
              videoId={youtubeVideoID}
              onReady={onReady}
              onPlay={onPlay}
            />
          </div>

          <h2>
            <b>170 000</b> наших бурёнок живут на <b>230</b> фермах.*
          </h2>

          <MatroskinMessage
            text={`Узнайте, как мы заботимся <br class="mobile" /> о качестве <br /> и натуральности, <br class="mobile" /> и выиграйте приз в викторине!`}
          />

          <img className="intro-matroskin" src={matroskin} alt="matroskin" />

          <span className="intro-date">*По состоянию на 18.07.2022</span>

          <button className="intro-scroll" onClick={scrollToVideo}>
            <img src={scrollMobile} alt="scroll-button" />
          </button>

          <button className="intro-button" onClick={onClose}>
            Далее
          </button>

          <button className="intro-close" onClick={onClose}>
            <img src={closeIcon} alt="close-icon" />
          </button>
        </div>
      )}
    </div>
  );
};

Intro.propTypes = {
  hideElements: PropTypes.bool,
  region: PropTypes.object,
  onStartVideo: PropTypes.func,
  currentTab: PropTypes.string,
  isMobile: PropTypes.bool,
  onClose: PropTypes.func,
  showMap: PropTypes.func,
};

export default Intro;
