import { useState } from 'react';
import PropTypes from 'prop-types';
import { deviceMedia } from 'enums/device';
import { preventModalClick } from 'helpers';
import InfoSpotProvider from 'components/InfoSpots/InfoSpotProvider';
import QualityLineDesktop from 'components/Quality/QualityLineDesktop';
import QualityLineTablet from 'components/Quality/QualityLineTablet';
import QualityLineMobile from 'components/Quality/QualityLineMobile';

import houseIcon from 'assets/img/icon-house.svg';
import arrowIcon from 'assets/img/icon-arrow-right.svg';
import clouds from 'assets/img/quality/clouds.png';
import cloudsTablet from 'assets/img/quality/clouds--tablet.png';
import cloudsMobile from 'assets/img/quality/clouds--mobile.png';
import quality1 from 'assets/img/quality/quality-1.png';
import quality2 from 'assets/img/quality/quality-2.png';
import quality3 from 'assets/img/quality/quality-3.png';
import quality4 from 'assets/img/quality/quality-4.png';
import quality5 from 'assets/img/quality/quality-5.png';

const QualityContent = ({ onClose }) => {
  const [infoSpotId, setInfoSpotId] = useState(null);

  const openInfoSpot = (id) => {
    setInfoSpotId(id);
  };

  const closeInfoSpot = () => {
    setInfoSpotId(null);
  };

  const openNextInfoSpot = () => {
    if (infoSpotId === 'infospot-7') {
      openInfoSpot('infospot-8');
      return;
    }
    openInfoSpot('infospot-7');
  };

  const onScroll = () => {
    const overflow = document.querySelector('.quality-tab-content-overflow');
    const overlay = document.querySelector('.quality-tab-content-overlay');
    const Dpath = document.querySelector('.quality-tab-line--desktop path');
    const Tpath = document.querySelector('.quality-tab-line--tablet path');
    const Mpath = document.querySelector('.quality-tab-line--mobile path');

    const top = overlay.scrollTop;
    const height = overflow.offsetHeight - overlay.offsetHeight;
    const delta = top / height;

    const update = (element) => {
      if (!element) return;

      const reversed = element.dataset.reversed === '1';
      const lehgth = Number(element.getAttribute('stroke-dasharray'));
      const offset = lehgth + lehgth * delta * (reversed ? 1 : -1);

      element.setAttribute('stroke-dashoffset', offset);

      const circles = element.parentElement.querySelectorAll('circle');
      circles.forEach((circle) => {
        const opacityStart = Number(circle.dataset.opacityStart);
        const opacityEnd = Number(circle.dataset.opacityEnd);
        const imageIndex = Number(circle.dataset.imageIndex);
        const property = circle.dataset.property;

        const opacity = 1 - (opacityEnd - delta) / (opacityEnd - opacityStart);
        const value = Math.min(Math.max(opacity, 0), 1);

        circle.style.opacity = value;

        const text = document.querySelector(`.quality-tab-step--${imageIndex}`);
        const image = document.querySelector(
          `.quality-tab-image--${imageIndex} img`
        );

        if (text) {
          text.style.setProperty(property, value);
        }

        if (image) {
          image.style.setProperty(property, value);
        }
      });
    };

    [Dpath, Tpath, Mpath].forEach(update);
  };

  const qualityImages = [
    { img: quality1, onClick: () => openInfoSpot('infospot-7') },
    { img: quality2, onClick: () => openInfoSpot('infospot-9') },
    { img: quality3, onClick: () => openInfoSpot('infospot-10') },
    { img: quality4, onClick: () => openInfoSpot('infospot-11') },
    { img: quality5, onClick: () => openInfoSpot('infospot-12') },
  ];

  return (
    <div className="quality-tab-content">
      <div className="quality-tab-content-overlay" onScroll={onScroll}>
        <div className="quality-tab-content-overflow">
          <picture>
            <source srcSet={cloudsMobile} media={deviceMedia.mobile} />
            <source srcSet={cloudsTablet} media={deviceMedia.tablet} />
            <img className="quality-tab-clouds" src={clouds} alt="clouds" />
          </picture>

          <div className={`quality-tab-line ${infoSpotId ? 'hidden' : ''}`}>
            <QualityLineDesktop />
            <QualityLineTablet />
            <QualityLineMobile />
          </div>

          <div
            className={`quality-tab-infospot-overlay ${
              infoSpotId ? 'quality-tab-infospot-overlay--active' : ''
            }`}
            onClick={closeInfoSpot}
          >
            <InfoSpotProvider id={infoSpotId} onClose={closeInfoSpot} />
            <div className="quality-tab-buttons" onClick={preventModalClick}>
              {(infoSpotId === 'infospot-7' || infoSpotId === 'infospot-8') && (
                <button
                  className="quality-tab-arrow quality-tab-arrow--prev"
                  onClick={openNextInfoSpot}
                >
                  <img src={arrowIcon} alt="arrow-icon" />
                </button>
              )}

              <button
                className="quality-tab-close"
                onClick={onClose}
                disabled={infoSpotId}
              >
                <img src={houseIcon} alt="house-icon" />
              </button>

              {(infoSpotId === 'infospot-7' || infoSpotId === 'infospot-8') && (
                <button
                  className="quality-tab-arrow quality-tab-arrow--next"
                  onClick={openNextInfoSpot}
                >
                  <img src={arrowIcon} alt="arrow-icon" />
                </button>
              )}
            </div>
          </div>

          <h1 className={infoSpotId ? 'hidden' : ''}>Контроль качества</h1>

          <div className={`quality-tab-steps ${infoSpotId ? 'hidden' : ''}`}>
            <div className="quality-tab-step quality-tab-step--0">
              <h2>1.</h2>
              <p>
                <span>Правильное доение</span>
                <a onClick={() => openInfoSpot('infospot-7')}>Подробнее...</a>
              </p>
            </div>
            <div className="quality-tab-step quality-tab-step--1">
              <h2>2.</h2>
              <p>
                <span>Проверка на ферме</span>
                <a onClick={() => openInfoSpot('infospot-9')}>Подробнее...</a>
              </p>
            </div>
            <div className="quality-tab-step quality-tab-step--2">
              <h2>3.</h2>
              <p>
                <span>Быстрая доставка</span>
                <a onClick={() => openInfoSpot('infospot-10')}>Подробнее...</a>
              </p>
            </div>
            <div className="quality-tab-step quality-tab-step--3">
              <h2>4.</h2>
              <p>
                <span>Проверка на заводе</span>
                <a onClick={() => openInfoSpot('infospot-11')}>Подробнее...</a>
              </p>
            </div>
            <div className="quality-tab-step quality-tab-step--4">
              <h2>5.</h2>
              <p>
                <span>
                  Контроль <br /> на производстве
                </span>
                <a onClick={() => openInfoSpot('infospot-12')}>Подробнее...</a>
              </p>
            </div>
          </div>

          {qualityImages.map((img, i) => (
            <div
              className={`quality-tab-image quality-tab-image--${i} ${
                infoSpotId ? 'hidden' : ''
              }`}
              key={i}
            >
              <img onClick={img.onClick} src={img.img} alt={`quality-${i}`} />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

QualityContent.propTypes = {
  onClose: PropTypes.func,
};

export default QualityContent;
