import PropTypes from 'prop-types';
import { preventModalClick } from 'helpers';

import closeIcon from 'assets/img/matroskin/matroskin-close.svg';

const GeolocationConfirm = ({
  onClose,
  regionData,
  accept,
  decline,
  disabled,
}) => {
  return (
    <div
      className="geolocation-tab-content geolocation-tab-confirm"
      onClick={preventModalClick}
    >
      <h1 className="geolocation-tab-confirm-title">{regionData.name}</h1>
      <p className="geolocation-tab-confirm-description">
        Вы сейчас здесь находитесь?
      </p>

      <div className="geolocation-tab-buttons">
        <button
          className="geolocation-tab-confirm-button"
          onClick={() => accept(regionData.id)}
          disabled={disabled}
        >
          Да
        </button>

        <button
          className="geolocation-tab-confirm-button"
          onClick={decline}
          disabled={disabled}
        >
          Нет
        </button>
      </div>

      <button className="geolocation-tab-close" onClick={onClose}>
        <img src={closeIcon} alt="close-icon" />
      </button>
    </div>
  );
};

GeolocationConfirm.propTypes = {
  regionData: PropTypes.object,
  disabled: PropTypes.bool,
  onClose: PropTypes.func,
  decline: PropTypes.func,
  accept: PropTypes.func,
};

export default GeolocationConfirm;
