import categoryBrushIcon from 'assets/img/care/care-category-brush-icon.svg';
import categoryPillowIcon from 'assets/img/care/care-category-pillow-icon.svg';
import categoryTablewareIcon from 'assets/img/care/care-category-tableware-icon.svg';
import categorySunIcon from 'assets/img/care/care-category-sun-icon.svg';

import massage from 'assets/img/care/care-game-massage.png';
import flooring from 'assets/img/care/care-game-flooring.png';
import food from 'assets/img/care/care-game-food.png';
import climate from 'assets/img/care/care-game-climate.png';

import climate0 from 'assets/img/care/climate0.svg';
import climate1 from 'assets/img/care/climate1.svg';
import climate2 from 'assets/img/care/climate2.svg';

import food0 from 'assets/img/care/tableware0.svg';
import food1 from 'assets/img/care/tableware1.svg';
import food2 from 'assets/img/care/tableware2.svg';

import massage0 from 'assets/img/care/massage0.svg';
import massage1 from 'assets/img/care/massage2.svg';
import massage2 from 'assets/img/care/massage1.svg';

import flooring0 from 'assets/img/care/flooring0.svg';
import flooring1 from 'assets/img/care/flooring1.svg';
import flooring2 from 'assets/img/care/flooring2.svg';

export const allCategories = [
  {
    title: 'Массаж',
    question:
      'Наши бурёнки любят <br />спинку почесать. <br /><br class="br-mobile" />Что порекомендуете?',
    description:
      'Чтобы коровы оставались чистыми и довольными, <br />у нас на ферме специальные массажёры-чесалки имеются.',
    img: massage,
    cowImg: categoryBrushIcon,
    options: [
      {
        title: 'Грабли',
        img: massage0,
        correct: false,
      },
      {
        title: 'Чесалка',
        img: massage1,
        correct: true,
      },
      {
        title: 'Забор',
        img: massage2,
        correct: false,
      },
    ],
  },
  {
    title: 'Отдых',
    question: 'Нашим коровкам самое время отдохнуть. <br />На что им прилечь?',
    description:
      'Наши коровки отдыхают на <br />специальных резиновых ковриках с периной из опилок. А у телят подстилка из свежей соломы.',
    img: flooring,
    cowImg: categoryPillowIcon,
    options: [
      {
        title: 'Коврик',
        img: flooring0,
        correct: true,
      },
      {
        title: 'Шезлонг',
        img: flooring1,
        correct: false,
      },
      {
        title: 'Скамейка',
        img: flooring2,
        correct: false,
      },
    ],
  },
  {
    title: 'Еда',
    question:
      'Коровки <br /><br class="br-mobile" />проголодались. Чем <br />будете кормить?',
    description: 'Покормим Мурку <br />травами и злаками — самое полезное!',
    img: food,
    cowImg: categoryTablewareIcon,
    options: [
      {
        title: 'Травы и злаки',
        img: food0,
        correct: true,
      },
      {
        title: 'Зефир',
        img: food1,
        correct: false,
      },
      {
        title: 'Чипсы',
        img: food2,
        correct: false,
      },
    ],
  },
  {
    title: 'Климат',
    question: 'Бурёнкам стало жарко. <br />Как будем охлаждать коровник?',
    description:
      'Чтобы даже жарким <br class="br-mobile" />летом <br />бурёнкам комфортно <br />было, нужны специальные вентиляторы. <br /><br class="br-mobile" />Фен и веер не справятся. Включаем!',
    img: climate,
    cowImg: categorySunIcon,
    options: [
      {
        title: 'Вентилятор',
        img: climate0,
        correct: true,
      },
      {
        title: 'Фен',
        img: climate1,
        correct: false,
      },
      {
        title: 'Веер',
        img: climate2,
        correct: false,
      },
    ],
  },
];
