const QualityLineMobile = () => {
  return (
    <svg
      viewBox="0 0 244 630"
      className="quality-tab-line--mobile"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
    >
      <path
        d="M165 624C218.815 609.095 237 585.966 237 532L81 445C76.6505 398.371 -27.0181 427.747 9.9545 362L164.966 270.796C179.11 215.722 260.478 258.873 225.214 171.223L78.1064 102.845C50.811 57.2535 -3.98619 69.7662 35.0674 6"
        stroke="#FFFFFF"
        strokeWidth="2"
        strokeLinejoin="round"
        strokeDasharray="1044"
        strokeDashoffset="1044"
        data-reversed="1"
      />
      <path
        d="M165 624C218.815 609.095 237 585.966 237 532L81 445C76.6505 398.371 -27.0181 427.747 9.9545 362L164.966 270.796C179.11 215.722 260.478 258.873 225.214 171.223L78.1064 102.845C50.811 57.2535 -3.98619 69.7662 35.0674 6"
        stroke="#BFE0FA"
        strokeWidth="4"
        strokeLinejoin="round"
        strokeDasharray="6 6"
      />
      <path
        d="M35.0674 6C-3.98619 69.7662 50.811 57.2535 78.1064 102.845"
        stroke="#FFFFFF"
        strokeWidth="2"
        strokeLinejoin="round"
        strokeDasharray="6 6"
      />
      <path
        d="M237 532L81 445"
        stroke="#BFE0FA"
        strokeWidth="4"
        strokeLinejoin="round"
      />
      <path
        d="M164.966 270.796L9.95453 362"
        stroke="#BFE0FA"
        strokeWidth="4"
        strokeLinejoin="round"
      />
      <path
        d="M78.1064 102.845L225.214 171.223"
        stroke="#BFE0FA"
        strokeWidth="4"
        strokeLinejoin="round"
      />
      <circle
        cx="165"
        cy="625"
        r="5"
        fill="none"
        data-opacity-start="0.90"
        data-opacity-end="1"
        data-image-index="4"
        data-property="--mobile-value"
      />
      <circle
        cx="236"
        cy="532"
        r="5"
        fill="#FFFFFF"
        data-opacity-start="0.874"
        data-opacity-end="0.974"
        data-property="--mobile-value"
      />
      <circle
        cx="83"
        cy="441"
        r="5"
        fill="#FFFFFF"
        data-opacity-start="0.705"
        data-opacity-end="0.805"
        data-image-index="3"
        data-property="--mobile-value"
      />
      <circle
        cx="11"
        cy="358"
        r="5"
        fill="#FFFFFF"
        data-opacity-start="0.576"
        data-opacity-end="0.676"
        data-property="--mobile-value"
      />
      <circle
        cx="165"
        cy="267"
        r="5"
        fill="#FFFFFF"
        data-opacity-start="0.403"
        data-opacity-end="0.503"
        data-image-index="2"
        data-property="--mobile-value"
      />
      <circle
        cx="225"
        cy="172"
        r="5"
        fill="#FFFFFF"
        data-opacity-start="0.275"
        data-opacity-end="0.375"
        data-property="--mobile-value"
      />
      <circle
        cx="80"
        cy="102"
        r="5"
        fill="#FFFFFF"
        data-opacity-start="0"
        data-opacity-end="0"
        data-image-index="1"
        data-property="--mobile-value"
      />
      <circle
        cx="33"
        cy="6"
        r="5"
        fill="#FFFFFF"
        data-opacity-start="0"
        data-opacity-end="0"
        data-image-index="0"
        data-property="--mobile-value"
      />
    </svg>
  );
};

export default QualityLineMobile;
