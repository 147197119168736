import { observer } from 'mobx-react';
import { panoramaStore } from 'store';
import InfoSpotProvider from 'components/InfoSpots/InfoSpotProvider';

import houseIcon from 'assets/img/icon-house.svg';
import arrowIcon from 'assets/img/icon-arrow-right.svg';

const Panorama = observer(({ visible, onClose }) => {
  const closeInfoModal = () => panoramaStore.setCurrentInfospotId(null);

  const getClassName = () => {
    const className = 'panorama-section';
    if (!visible) return className;
    return `${className} panorama-section--visible`;
  };

  const openNextPanorama = () => {
    const id = panoramaStore.currentPanorama?.linkId;
    panoramaStore.openPanorama(id);
  };

  return (
    <section className={getClassName()}>
      <div className="panorama-container" />

      <div className="panorama-buttons">
        {panoramaStore.currentPanorama?.linkId && (
          <button
            className="panorama-arrow panorama-arrow--prev"
            onClick={openNextPanorama}
          >
            <img src={arrowIcon} alt="arrow-icon" />
          </button>
        )}

        <button className="panorama-close" onClick={onClose}>
          <img src={houseIcon} alt="house-icon" />
        </button>

        {panoramaStore.currentPanorama?.linkId && (
          <button
            className="panorama-arrow panorama-arrow--next"
            onClick={openNextPanorama}
          >
            <img src={arrowIcon} alt="arrow-icon" />
          </button>
        )}
      </div>

      <InfoSpotProvider
        id={panoramaStore.currentInfospotId}
        onClose={closeInfoModal}
      />
    </section>
  );
});

export default Panorama;
