import { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { matroskinStore } from 'store';
import InfoModal from 'components/InfoModals/InfoModal';
import QualityContent from 'components/Quality/QualityContent';
import { setDataLayer } from 'gtm';

import image from 'assets/img/info-modals/quality.jpg';

const QualityTab = observer(({ onClose }) => {
  const [currentStep, setCurrentStep] = useState(1);

  useEffect(() => {
    matroskinStore.setCurrentStep('quality');
  }, []);

  useEffect(() => {
    if (currentStep === 2) {
      setDataLayer({
        event: 'GAEvent',
        eventCategory: 'click',
        eventAction: 'button',
        eventLabel: 'more-quality',
      });
      matroskinStore.show();
    }
  }, [currentStep]);

  return (
    <div className="quality-tab">
      {currentStep === 1 && (
        <InfoModal
          img={image}
          blur={true}
          title="Контроль качества"
          text="Каждый день я слежу за качеством молока. И ни одну партию без тщательной проверки не пропущу."
          description={`Наталия Владимировна, <br /> лаборант`}
          onClick={() => setCurrentStep(2)}
          onClose={onClose}
        />
      )}

      {currentStep === 2 && <QualityContent onClose={onClose} />}
    </div>
  );
});

QualityTab.propTypes = {
  onClose: PropTypes.func,
};

export default QualityTab;
