import preview1 from 'assets/img/academy/preview-1.jpg';
import preview2 from 'assets/img/academy/preview-2.jpg';
import preview3 from 'assets/img/academy/preview-3.jpg';
import preview4 from 'assets/img/academy/preview-4.jpg';
import preview5 from 'assets/img/academy/preview-5.jpg';
import preview6 from 'assets/img/academy/preview-6.jpg';
import preview7 from 'assets/img/academy/preview-7.jpg';
import preview8 from 'assets/img/academy/preview-8.jpg';
import preview9 from 'assets/img/academy/preview-9.jpg';

export const resultMessages = {
  0: 'Скорее всего вы просто угадываете <br /> <br class="mobile" /> ответы. Давайте пройдём вместе <br /> <br class="mobile" /> онлайн-тур по нашей ферме!',
  1: 'Кажется, вы мимо нашей <br /> фермы прошли. Хотите ещё <br /> раз всё посмотреть?',
  2: 'Зашли на нашу ферму и сразу <br /> <br class="mobile" /> вышли? А наши коровки <br /> хотели <br class="mobile" /> познакомиться!',
  3: 'Что-то запомнилось, но вы <br /> <br class="mobile" /> можете лучше! Давайте ещё <br /> <br class="mobile" /> раз попробуем.',
  4: 'Ого! Да вы почти Кандидат <br /> <br class="mobile" /> Молочных Наук. <br /> Получите <br class="mobile" /> награду — заслужили!',
  5: 'Поздравляем! Вы настоящий <br /> <br class="mobile" /> Профессор Молочных Наук. <br /> <br class="mobile" /> Получите награду — заслужили!',
};

export const allQuestions = [
  {
    preview: preview1,
    question:
      'Сколько на самом деле ферм и <br class="mobile" /> бурёнок у Простоквашино?',
    description:
      'Важно, чтобы <br class="mobile" /> качественного молока <br /> на <br class="mobile" /> всю страну хватало.',
    answers: [
      { title: '230 ферм и 5 000 коров', correct: false },
      { title: '230 ферм и 170 000 коров', correct: true },
      { title: '10 ферм и 20 коров', correct: false },
    ],
  },
  {
    preview: preview2,
    question:
      'Чем правильнее кормить <br class="mobile" /> коров, чтобы молоко <br class="mobile" /> вкусным получалось?',
    description:
      'Для вкусного молока наши <br class="mobile" /> коровы травы и злаки <br class="mobile" /> с родных полей едят.',
    answers: [
      { title: 'Травы и злаки', correct: true },
      { title: 'Ватрушки и печенье', correct: false },
      { title: 'Яблоки и морковь', correct: false },
    ],
  },
  {
    preview: preview3,
    question:
      'Зачем на фермах <br class="mobile" /> Простоквашино коровы <br class="mobile" /> электронные датчики носят?',
    description:
      'Датчики помогают следить за <br class="mobile" /> здоровьем, активностью и <br class="mobile" /> надоем.',
    answers: [
      {
        title:
          'Чтобы ветеринары могли <br class="mobile" /> следить за их здоровьем',
        correct: false,
      },
      {
        title:
          'Чтобы ветеринары могли следить <br class="mobile" /> за их активностью и надоем',
        correct: false,
      },
      { title: 'Оба варианта верны', correct: true },
    ],
  },
  {
    preview: preview4,
    question:
      'Какой показатель на фермах <br class="mobile" /> Простоквашино оценивают <br class="mobile" /> по 34 параметрам?',
    description:
      '34 параметра благополучия <br class="mobile" /> коров. <br /> От климата и до общего <br class="mobile" /> состояния бурёнок.',
    answers: [
      { title: 'Математические способности коров', correct: false },
      { title: 'Уровень надоя коровы', correct: false },
      { title: 'Благополучие коров', correct: true },
    ],
  },
  {
    preview: preview5,
    question:
      'По скольким параметрам мы <br class="mobile" /> проверяем качество молока <br class="mobile" /> каждый день?',
    description:
      'Всё молоко с ферм мы <br class="mobile" /> проверяем на заводе <br class="mobile" /> минимум по 13 параметрам. <br class="mobile" /> За качество ручаемся!',
    answers: [
      { title: 'Ровно по 3 параметрам', correct: false },
      { title: 'Минимум по 13 параметрам', correct: true },
      { title: 'Проверяем только на антибиотики', correct: false },
    ],
  },
  {
    preview: preview6,
    question:
      'Как часто на фермах <br class="mobile" /> Простоквашино корм <br class="mobile" /> бурёнкам раздают?',
    description:
      'Коров сытно кормят дважды в <br class="mobile" /> день, <br /> а помогает в этом <br class="mobile" /> кормораздатчик.',
    answers: [
      { title: '2 раза в день', correct: true },
      { title: '1 раз в полчаса', correct: false },
      { title: '1 раз в день', correct: false },
    ],
  },
  {
    preview: preview7,
    question:
      'Как на фермах <br class="mobile" /> Простоквашино следят <br class="mobile" /> за здоровьем бурёнок?',
    description:
      'Для здоровья бурёнок нужна <br class="mobile" /> профилактика: регулярные <br class="mobile" /> осмотры, анализы, лечебные <br class="mobile" /> «ванны» для <br /> копыт и не только.',
    answers: [
      {
        title:
          'Проводят осмотры, анализы <br class="mobile" /> и устраивают «ванны» для копыт',
        correct: true,
      },
      { title: 'Задают вопросы о самочувствии', correct: false },
      { title: 'Следят в биноколь', correct: false },
    ],
  },
  {
    preview: preview8,
    question:
      'Где на ферме <br class="mobile" /> Простоквашино бурёнки <br class="mobile" /> спать любят?',
    description:
      'Коровы спят на специальных <br class="mobile" /> <br /> резиновых ковриках. А <br class="mobile" /> опилки им досыпают для <br class="mobile" /> мягкости.',
    answers: [
      { title: 'Бетонные полы', correct: false },
      { title: 'Коровы спят стоя', correct: false },
      { title: 'Резиновый коврик с опилками', correct: true },
    ],
  },
  {
    preview: preview9,
    question:
      'Когда на ферме коровы <br class="mobile" /> массажёры-чесалки могут <br class="mobile" /> использовать?',
    description:
      'Коровы сами приходят на <br class="mobile" /> массаж, когда захотят. <br class="mobile" /> Чесалка всегда <br /> доступна в <br class="mobile" /> коровнике.',
    answers: [
      { title: 'Когда приходит ветеринар-массажист', correct: false },
      { title: 'В любое время', correct: true },
      { title: 'Только на дойке', correct: false },
    ],
  },
];
