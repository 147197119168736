import PropTypes from 'prop-types';

import React, { useState, useEffect } from 'react';
import { matroskinStore } from 'store';
import { regionsData } from 'enums/regionsData';

import matroskinMessagePad from 'assets/img/matroskin/matroskin-message-pad.svg';
import matroskinMessagePadDark from 'assets/img/matroskin/matroskin-message-pad--dark.svg';

const MatroskinMessage = ({
  active = true,
  image = null,
  image2 = null,
  text = '',
  text2 = '',
}) => {
  const region = regionsData.find((el) => el.url === window.location.pathname);
  const [counter, setCounter] = useState(1);
  const isFinalCounter = counter === 2;
  const buttonText = isFinalCounter ? 'Хорошо' : 'Далее';
  const getText = isFinalCounter ? text2 : text;
  const getImage = isFinalCounter ? image2 : image;

  useEffect(() => {
    setCounter(1);
  }, [active === false]);

  const changeCount = () => {
    if (isFinalCounter) {
      matroskinStore.close();
      setCounter(1);
    } else {
      setCounter(counter + 1);
    }
  };

  return (
    <div
      className={`matroskin-message ${
        active ? '' : 'matroskin-message--hidden'
      } ${image ? 'matroskin-message--big' : ''}`}
    >
      {image && (
        <img
          className="matroskin-message-image"
          src={getImage}
          alt="matroskin-image"
        />
      )}
      <p dangerouslySetInnerHTML={{ __html: getText }} />
      {region && text2 && (
        <div className="matroskin-message-group">
          <div className="matroskin-message-group-step">
            <span>{counter}</span>/2
          </div>
          <button onClick={changeCount}>{buttonText}</button>
        </div>
      )}
      <img
        className="matroskin-message-pad"
        src={matroskinMessagePad}
        alt="matroskin-message-pad"
      />
      <img
        className="matroskin-message-pad matroskin-message-pad--dark"
        src={matroskinMessagePadDark}
        alt="matroskin-message-pad--dark"
      />
    </div>
  );
};

MatroskinMessage.propTypes = {
  image: PropTypes.string,
  image2: PropTypes.string,
  text: PropTypes.string,
  text2: PropTypes.string,
  active: PropTypes.bool,
};

export default MatroskinMessage;
