import PropTypes from 'prop-types';
import PanoramaTutorial from 'components/PanoramaTutorial';
import InfoSpot from 'components/InfoSpots/InfoSpot';
import DialogModal from 'components/DialogModal';

import image1 from 'assets/img/infospots/infospot-1.jpg';
import image2 from 'assets/img/infospots/infospot-2.jpg';
import image3 from 'assets/img/infospots/infospot-3.jpg';
import video4 from 'assets/img/infospots/infospot-4.mp4';
import image5 from 'assets/img/infospots/infospot-5.jpg';
import image6 from 'assets/img/infospots/infospot-6.jpg';
import image7 from 'assets/img/infospots/infospot-7.jpg';
import image8 from 'assets/img/infospots/infospot-8.jpg';
import video9 from 'assets/img/infospots/infospot-9.mp4';

import dog from 'assets/img/dialog/dog.png';
import cat from 'assets/img/dialog/cat.png';
import pechkin from 'assets/img/dialog/pechkin.png';
import expert1 from 'assets/img/dialog/expert-1.png';
import expert2 from 'assets/img/dialog/expert-2.png';
import expert3 from 'assets/img/dialog/expert-3.png';

const InfoSpotProvider = ({ id, onClose }) => {
  const InfoSpots = {
    tutorial: <PanoramaTutorial onClose={onClose} />,
    'infospot-1': (
      <InfoSpot
        img={image1}
        title="Секреты правильного надоя"
        text="Для продуктов «Простоквашино» мы используем молоко только от здоровых коров. Если бурёнке нездоровится, то её сразу отправляют лечиться. И пока она не выздоровеет полностью, её молоко к нам на завод не поедет."
        onClose={onClose}
      />
    ),
    'infospot-2': (
      <InfoSpot
        img={image2}
        title="Профилактика"
        text="Секрет здоровья коров — не только забота об их самочувствии, но и регулярная профилактика. Измеряем температуру бурёнок, делаем анализы, обновляем их «педикюр» и несколько раз в неделю устраиваем лечебные «ванны» для здоровья копыт. Оздоровительные процедуры всем полезны!"
        onClose={onClose}
      />
    ),
    'infospot-3': (
      <DialogModal
        title="Электронные датчики"
        messages={[
          {
            img: dog,
            text: 'А что это за бижутерия <br /> <br class="mobile" /> у коров такая?',
          },
          {
            img: expert1,
            text: 'Это электронные датчики, <br class="mobile" /> которые <br /> помогают <br class="mobile" /> заботиться о здоровье коров, <br /> <br class="mobile" /> ежедневно следить за их <br class="mobile" /> активностью и <br /> надоем. Если <br class="mobile" /> показатели здоровья <br /> коровы <br class="mobile" /> отклоняются от нормы, то её <br /> <br class="mobile" /> сразу осматривает <br class="mobile" /> ветеринар.',
          },
          {
            img: cat,
            text: 'Это у тебя, Шарик, ошейник <br class="mobile" /> для красоты, <br /> а тут <br class="mobile" /> специалисты за здоровьем <br class="mobile" /> бурёнок <br /> следят внимательно!',
          },
        ]}
        onClose={onClose}
      />
    ),
    'infospot-4': (
      <InfoSpot
        img={image3}
        title="Баланс в питании"
        text="Вкусное и качественное молоко — дело тонкое. <br /> И правильное питание для бурёнок — наука точная. Поэтому сбалансированный рацион наших коров разрабатывают профессиональные диетологи."
        onClose={onClose}
      />
    ),
    'infospot-5': (
      <InfoSpot
        img={null}
        video={video4}
        playbackRate={0.8}
        title="Роботы-помощники"
        text="Чтобы бурёнки были сыты, их кормят дважды в день. <br /> И тут на помощь приходит машина-кормораздатчик — чтобы корма всем хватило. А ещё у коров всегда есть доступ к свежей воде: в коровниках есть специальные поилки, которые пополняются автоматически."
        onClose={onClose}
      />
    ),
    'infospot-6': (
      <DialogModal
        title="Состав корма"
        messages={[
          {
            img: dog,
            text: 'Ясное дело, что диета бурёнок <br /> важна. А чем их <br class="mobile" /> тут кормят?',
          },
          {
            img: expert2,
            text: 'Мы заботимся о том, чтобы <br class="mobile" /> коровы <br /> ели только <br class="mobile" /> натуральный корм и <br /> <br class="mobile" /> получали все необходимые <br /> <br class="mobile" /> витамины. Именно в травах <br class="mobile" /> и злаках <br /> больше всего <br class="mobile" /> питательных веществ, <br /> <br class="mobile" /> которые нужны для вкусного <br class="mobile" /> молока.',
          },
          {
            img: cat,
            text: 'Всё правильно: питание <br /> <br class="mobile" /> натур-р-ральное и с родных <br class="mobile" /> полей.',
          },
        ]}
        onClose={onClose}
      />
    ),
    'infospot-7': (
      <DialogModal
        title="Правильное доение"
        messages={[
          {
            img: cat,
            text: 'У нас контроль качества <br class="mobile" /> молочка <br /> с проверки первых <br class="mobile" /> молочных струек <br /> <br class="mobile" /> начинается.',
          },
          {
            img: expert3,
            text: 'Всё верно. До доения <br class="mobile" /> внимательно <br /> смотрим на <br class="mobile" /> цвет молока и его <br /> <br class="mobile" /> консистенцию. Если всё в <br class="mobile" /> полном <br /> порядке, то <br class="mobile" /> начинаем дойку.',
          },
          {
            img: pechkin,
            text: 'Вот она, забота о качестве какая!',
          },
        ]}
        onClose={onClose}
      />
    ),
    'infospot-8': (
      <InfoSpot
        img={image5}
        title="Чистая работа"
        text="Молочные продукты дольше хранятся, когда за чистотой следят тщательно. Поэтому дойка проводится чистыми доильными аппаратами, и молоко не соприкасается ни <br /> с человеком, ни даже с воздухом."
        onClose={onClose}
      />
    ),
    'infospot-9': (
      <InfoSpot
        img={image6}
        title="Проверка на ферме"
        text="После дойки на ферме молоко обязательно проверяют на отсутствие антибиотиков. И на завод только проверенное молоко отправляют."
        onClose={onClose}
      />
    ),
    'infospot-10': (
      <InfoSpot
        img={image7}
        title="Быстрая доставка"
        text="На завод молоко на специальном молоковозе привозят. <br /> А перед этим цистерну тщательно моют и само молоко охлаждают до 4 градусов для сохранения качества. Молоковоз везёт на завод уже холодное молоко."
        onClose={onClose}
      />
    ),
    'infospot-11': (
      <InfoSpot
        img={image8}
        title="Проверка на заводе"
        text="Молоко отправляется с фермы на завод каждый день. Потому и проверки на качество и чистоту молока на нём проводятся ежедневные, минимум по 13 показателям."
        onClose={onClose}
      />
    ),
    'infospot-12': (
      <InfoSpot
        img={null}
        video={video9}
        playbackRate={0.6}
        title="Контроль на производстве"
        text="И вот молоко уже на заводе. Тут мы регулярно ещё <br /> по 60 параметрам качество молока проверяем. Чтобы вы близких могли натуральной заботой окружить и вкусными продуктами порадовать."
        onClose={onClose}
      />
    ),
  };

  if (!id) return null;

  return InfoSpots[id];
};

InfoSpotProvider.propTypes = {
  onClose: PropTypes.func,
  id: PropTypes.string,
};

export default InfoSpotProvider;
