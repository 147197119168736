import PropTypes from 'prop-types';
import { setDataLayer } from '../../gtm';
import { preventModalClickWithLink } from 'helpers';
import { quizPromoCode, links } from 'config';
import { resultMessages } from 'enums/academyGameData';

import closeIcon from 'assets/img/matroskin/matroskin-close.svg';

const AcademyResult = ({ onClick, onClose, correct, count }) => {
  const copyPromoCode = () => navigator.clipboard.writeText(quizPromoCode);

  return (
    <div
      className="academy-tab-content academy-tab-result"
      onClick={preventModalClickWithLink}
    >
      <h1 className="academy-tab-result-title">Результат</h1>
      <h2 className="academy-tab-result-count">
        {correct}/{count}
      </h2>
      <p
        className="academy-tab-result-description"
        dangerouslySetInnerHTML={{
          __html: resultMessages[correct],
        }}
      />
      {correct < 4 &&
        setDataLayer({
          event: 'GAEvent',
          eventCategory: 'show-result',
          eventAction: 'fail',
          eventLabel: 'test',
        })}
      {correct >= 4 && (
        <>
          <a className="academy-tab-result-promocode" onClick={copyPromoCode}>
            {quizPromoCode}
          </a>
          <p className="academy-tab-result-promotext">
            Скидка 50% на всю продукцию <br /> Простоквашино в{' '}
            <a href={links.sbermarket} target="_blank" rel="noreferrer">
              Купер (СберМаркет)
            </a>
            .
          </p>
          <a
            className="academy-tab-result-rules"
            href={links.rules}
            target="_blank"
            rel="noreferrer"
          >
            Правила акции
          </a>
          {setDataLayer({
            event: 'GAEvent',
            eventCategory: 'show-result',
            eventAction: 'success',
            eventLabel: 'test',
          })}
        </>
      )}
      <button className="academy-tab-button" onClick={onClick}>
        Играть еще
      </button>
      <button className="academy-tab-close" onClick={onClose}>
        <img src={closeIcon} alt="close-icon" />
      </button>
    </div>
  );
};

AcademyResult.propTypes = {
  correct: PropTypes.number,
  onClick: PropTypes.func,
  onClose: PropTypes.func,
  count: PropTypes.number,
};

export default AcademyResult;
