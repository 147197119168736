import worker1 from 'assets/img/workers/worker-1.jpg';
import worker2 from 'assets/img/workers/worker-2.jpg';
import worker3 from 'assets/img/workers/worker-3.jpg';
import worker4 from 'assets/img/workers/worker-4.jpg';
import worker5 from 'assets/img/workers/worker-5.jpg';
import worker6 from 'assets/img/workers/worker-6.jpg';
import worker7 from 'assets/img/workers/worker-7.jpg';
import worker8 from 'assets/img/workers/worker-8.jpg';
import worker9 from 'assets/img/workers/worker-9.jpg';
import worker10 from 'assets/img/workers/worker-10.jpg';
import worker11 from 'assets/img/workers/worker-11.jpg';
import worker12 from 'assets/img/workers/worker-12.jpg';
import worker13 from 'assets/img/workers/worker-13.jpg';
import worker14 from 'assets/img/workers/worker-14.jpg';
import worker15 from 'assets/img/workers/worker-15.jpg';
import worker16 from 'assets/img/workers/worker-16.jpg';
import worker17 from 'assets/img/workers/worker-17.jpg';
import worker18 from 'assets/img/workers/worker-18.jpg';
import worker19 from 'assets/img/workers/worker-19.jpg';
import worker20 from 'assets/img/workers/worker-20.jpg';
import worker21 from 'assets/img/workers/worker-21.jpg';
import worker22 from 'assets/img/workers/worker-22.jpg';
import worker23 from 'assets/img/workers/worker-23.jpg';

export const workers = [
  {
    img: worker1,
    text: 'Александра Михайловна, <br /> ветеринар',
  },
  {
    img: worker2,
    text: 'Андрей Александрович, <br /> главный зоотехник ',
  },
  {
    img: worker3,
    text: 'Олеся Сергеевна, <br /> зооинженер',
  },
  {
    img: worker4,
    text: 'Светлана Дионисовна, <br /> телятница',
  },
  {
    img: worker5,
    text: 'Артур Николаевич, <br /> животновод',
  },
  {
    img: worker6,
    text: 'Иван Викторович, <br /> главный агроном',
  },
  {
    img: worker7,
    text: 'Галина Сергеевна, <br /> главный ветеринарный врач',
  },
  {
    img: worker8,
    text: 'Сергей Федорович, <br /> тракторист-машинист',
  },
  {
    img: worker9,
    text: 'Наталья Евгеньевна, <br /> оператор по кормлению',
  },
  {
    img: worker10,
    text: 'Наталия Владимировна, <br /> лаборант',
  },
  {
    img: worker11,
    text: 'Наталия Владимировна, <br /> оператор',
  },
  {
    img: worker13,
    text: 'Нелли Леонидовна, <br /> бригадир',
  },
  {
    img: worker14,
    text: 'Юлия Николаевна, <br /> зоотехник',
  },
  {
    img: worker15,
    text: 'Алексей Владимирович, <br /> водитель погрузчика',
  },
  {
    img: worker16,
    text: 'Виктор Анатольевич, <br /> тракторист-машинист',
  },
  {
    img: worker17,
    text: 'Григорий Васильевич, <br /> ветеринарный врач',
  },
  {
    img: worker18,
    text: 'Владимир Николаевич, <br /> водитель погрузчика',
  },
  {
    img: worker19,
    text: 'Ольга Анатольевна, <br /> доярка',
  },
  {
    img: worker20,
    text: 'Татьяна Юрьевна, <br /> доярка',
  },
  {
    img: worker21,
    text: 'Ольга Николаевна, <br /> доярка',
  },
  {
    img: worker22,
    text: 'Анна Николаевна, <br /> доярка',
  },
  {
    img: worker23,
    text: 'Наталия Юрьевна, <br /> доярка',
  },
];
