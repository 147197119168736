import { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { preventModalClick } from 'helpers';
import { deviceMedia } from 'enums/device';
import { setDataLayer } from 'gtm';

import CareTutorial from 'components/Care/CareTutorial';
import InfoModal from 'components/InfoModals/InfoModal';
import CareGame from 'components/Care/CareGame';

import image from 'assets/img/info-modals/care.jpg';
import backgroundMap from 'assets/img/care-background/care-background.png';
import backgroundMapTablet from 'assets/img/care-background/care-background-tablet.png';
import backgroundMapMobile from 'assets/img/care-background/care-background-mobile.png';

const CareTab = observer(({ onClose }) => {
  const [currentStep, setCurrentStep] = useState(1);
  useEffect(() => {
    if (currentStep === 2) {
      setDataLayer({
        event: 'GAEvent',
        eventCategory: 'click',
        eventAction: 'button',
        eventLabel: 'more-care',
      });
    }
  }, [currentStep]);

  return (
    <div className="care-tab">
      <div
        onClick={preventModalClick}
        className={`care-tab-background ${
          currentStep === 1 ? ' care-tab-background--not-opacity' : ''
        }`}
      >
        <div
          className={`${
            currentStep !== 1 ? 'care-tab-background--opacity-block' : ''
          }`}
        ></div>
        <picture>
          <source srcSet={backgroundMapMobile} media={deviceMedia.mobile} />
          <source srcSet={backgroundMapTablet} media={deviceMedia.tablet} />
          <img
            className="care-backgroung-img"
            src={backgroundMap}
            alt="background"
          />
        </picture>
      </div>

      {currentStep === 1 && (
        <InfoModal
          img={image}
          title="Уход и Забота"
          text="На каждой ферме благополучие коров проверяется по 34 параметрам. Мы следим за комфортом бурёнок и климатом в коровниках. Заботимся, чтобы у каждой был свободный доступ к корму и воде."
          description={`Олеся Сергеевна, <br /> зооинженер`}
          onClick={() => setCurrentStep(2)}
          onClose={onClose}
        />
      )}

      {currentStep === 2 && (
        <CareTutorial onClick={() => setCurrentStep(3)} onClose={onClose} />
      )}

      {currentStep === 3 && <CareGame onClose={onClose} />}
    </div>
  );
});

CareTab.propTypes = {
  onClose: PropTypes.func,
};

export default CareTab;
