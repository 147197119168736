import { observer } from 'mobx-react';
import { matroskinStore } from 'store';
import MatroskinMessage from 'components/MatroskinMessage';
import { setDataLayer } from 'gtm';

import matroskin from 'assets/img/matroskin/matroskin.png';
import matroskinClose from 'assets/img/matroskin/matroskin-close.svg';
import matroskinOpen from 'assets/img/matroskin/matroskin-open.svg';

const Matroskin = observer(() => {
  const MatroskinButtons = () => {
    if (!matroskinStore.clickable) return null;

    if (matroskinStore.active) {
      return (
        <button
          onClick={() => {
            matroskinStore.close();
          }}
        >
          <img src={matroskinClose} />
        </button>
      );
    }

    return (
      <button
        onClick={() => {
          setDataLayer({
            event: 'GAEvent',
            eventCategory: 'matroskin',
            eventAction: 'success',
            eventLabel: 'open',
          });
          matroskinStore.open();
        }}
      >
        <img src={matroskinOpen} />
      </button>
    );
  };

  return (
    <div className={matroskinStore.className}>
      <div
        className="matroskin-background"
        onClick={() => {
          setDataLayer({
            event: 'GAEvent',
            eventCategory: 'matroskin',
            eventAction: 'success',
            eventLabel: 'close',
          });
          matroskinStore.close();
        }}
      />

      <div className="matroskin">
        <img
          src={matroskin}
          alt="matroskin"
          className="matroskin-image"
          onClick={() => {
            setDataLayer({
              event: 'GAEvent',
              eventCategory: 'matroskin',
              eventAction: 'success',
              eventLabel: matroskinStore.active ? 'close' : 'open',
            });
            matroskinStore.toggle();
          }}
        />

        <MatroskinMessage
          active={matroskinStore.active}
          image={matroskinStore.image}
          image2={matroskinStore.image2}
          text={matroskinStore.message}
          text2={matroskinStore.message2}
        />

        <MatroskinButtons />
      </div>
    </div>
  );
});

export default Matroskin;
