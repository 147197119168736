import { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { deviceSizes } from 'enums/device';
import { regionsData } from 'enums/regionsData';
import { scrollToVideo } from 'helpers';
import { panoramaStore, matroskinStore } from 'store';
import { setDataLayer } from 'gtm';

import { initGTM } from './gtm';

import Panorama from 'components/Panorama';
import Navbar from 'components/Navbar';
import InfoModalsProvider from 'components/InfoModals/InfoModalsProvider';
import GeolocationTab from 'components/Geolocation/GeolocationTab';
import QualityTab from 'components/Quality/QualityTab';
import AcademyTab from 'components/Academy/AcademyTab';
import WorkersTab from 'components/Workers/WorkersTab';
import Background from 'components/Background';
import CareTab from 'components/Care/CareTab';
import Matroskin from 'components/Matroskin';
import Loader from 'components/Loader';
import Intro from 'components/Intro';
import IntroVideo from 'components/IntroVideo';

initGTM();
const App = observer(() => {
  const [resizeInterval, setResizeInterval] = useState(null);
  const [windowSize, setWindowSize] = useState({ width: 0, height: 0 });
  const [panoramaState, setPanoramaState] = useState(false);
  const [instructionShown, setInstructionShownState] = useState(false);
  const [currentTab, setCurrentTab] = useState('intro');
  const [vh, setVh] = useState('calc(100vh / 100)');

  const region = regionsData.find((el) => el.url === window.location.pathname);
  const isVisiblePage = region || window.location.pathname === '/';

  const isMobile = windowSize.width <= deviceSizes.mobile;

  useEffect(() => {
    const container = document.querySelector('.panorama-container');
    panoramaStore.init(container);

    onResize();
  }, []);

  useEffect(() => {
    window.onresize = onResize;
    clearInterval(resizeInterval);
    setResizeInterval(setInterval(onResize, 1000));
  }, [windowSize]);

  useEffect(() => {
    const application = document.querySelector('.application');
    application.onscroll = onScroll;
  }, [instructionShown]);

  const onResize = () => {
    const width = window.innerWidth;
    const height = window.innerHeight;

    if (width === windowSize.width && height === windowSize.height) return;

    setVh(`${height / 100}px`);
    setWindowSize({ width, height });
    panoramaStore.viewer.onWindowResize(width, height);
  };

  const onScroll = () => {
    const application = document.querySelector('.application');
    const background = document.querySelector('.background');
    const care = document.querySelector('.background-location-area-care');

    const scale = Number(background.style.getPropertyValue('--scale-mobile'));
    const distance = care.offsetTop * scale - window.innerHeight;

    if (application.scrollTop <= distance) {
      matroskinStore.hide();
      return;
    }

    showInstructions();
    matroskinStore.show();
  };

  const showInstructions = () => {
    if (currentTab === 'video') {
      closeTab();

      if (region) {
        matroskinStore.show();
      }
      return;
    }

    if (instructionShown) return;

    setCurrentTab(null);
    matroskinStore.setCurrentStep('intro');
    matroskinStore.show();
    matroskinStore.open();

    setInstructionShownState(true);
  };

  const showMap = () => {
    setCurrentTab(null);
    matroskinStore.setCurrentStep('intro');
    matroskinStore.show();
  };

  const showViewer = () => {
    setPanoramaState(true);
    panoramaStore.openPanorama(currentTab);
    panoramaStore.setCurrentInfospotId('tutorial');

    matroskinStore.setCurrentStep('panorama-tutorial');
    matroskinStore.setClickableState(false);
    matroskinStore.show();
    matroskinStore.open();
  };

  const gtm = () => {
    setDataLayer({
      event: 'GAEvent',
      eventCategory: 'click',
      eventAction: 'button',
      eventLabel: `more-${currentTab}`,
    });
    showViewer();
  };

  const hideViewer = () => {
    setPanoramaState(false);
    panoramaStore.openPanorama(null);
  };

  const closeViewer = () => {
    hideViewer();
    closeTab();
  };

  const closeTab = () => {
    setCurrentTab(null);

    if (!instructionShown) return;

    matroskinStore.setCurrentStep('map');
    matroskinStore.show();
  };

  const openTab = (tab, scroll = true) => {
    if (isMobile && tab === 'video' && scroll && region) {
      scrollToVideo();
    }

    matroskinStore.close();
    matroskinStore.hide();
    matroskinStore.setClickableState(true);
    panoramaStore.setCurrentInfospotId(null);
    setCurrentTab(tab);

    const panorama = panoramaStore.getPanoramaById(tab);
    if (panorama && !panoramaState) {
      panoramaStore.rotateTo(panorama?.initialLookAt, 0);
    }

    hideViewer();
  };

  const onStartVideo = () => {
    if (currentTab === 'intro') return;
    openTab('video', false);
  };

  const allowScroll =
    !panoramaState &&
    (!matroskinStore.active || matroskinStore.currentStep === 'intro') &&
    (!currentTab || currentTab === 'intro' || currentTab === 'video');

  return (
    <div
      className={`application ${allowScroll ? '' : 'application--noscroll'}`}
      style={{ '--vh': vh }}
    >
      {isVisiblePage ? (
        <div>
          <Background openTab={openTab} currentTab={currentTab} />

          <Navbar openTab={openTab} currentTab={currentTab} />

          <Panorama visible={panoramaState} onClose={closeViewer} />

          <InfoModalsProvider
            id={currentTab}
            onClose={closeTab}
            onClick={gtm}
          />

          {currentTab === 'care' && <CareTab onClose={closeTab} />}

          {currentTab === 'academy' && <AcademyTab onClose={closeTab} />}

          {currentTab === 'quality' && <QualityTab onClose={closeTab} />}

          {currentTab === 'workers' && <WorkersTab onClose={closeTab} />}

          {currentTab === 'geolocation' && (
            <GeolocationTab onClose={closeTab} />
          )}

          {(currentTab === 'intro' ||
            (currentTab === 'video' && !region) ||
            isMobile) && (
            <Intro
              onClose={showInstructions}
              showMap={showMap}
              onStartVideo={onStartVideo}
              currentTab={currentTab}
              isMobile={isMobile}
              hideElements={currentTab === 'video'}
              region={region}
            />
          )}

          {region && currentTab === 'video' && (
            <IntroVideo
              onClose={showInstructions}
              onStartVideo={onStartVideo}
              currentTab={currentTab}
            />
          )}

          <Matroskin />

          <Loader />
        </div>
      ) : (
        <div className="page-404">Страница не найдена</div>
      )}
    </div>
  );
});

export default App;
