const QualityLineTablet = () => {
  return (
    <svg
      viewBox="0 0 465 988"
      className="quality-tab-line--tablet"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
    >
      <path
        d="M134 971C216.91 870.814 262.07 936.765 313.933 948.345C365.795 959.924 422.245 983.586 448 898L287 690.645C114.665 706.561 135.258 638.085 35 597L36 403C126 279 183.5 384 290 298L330.26 137.147C322.707 83.2507 249.083 99.0632 177.505 103.598C105.927 108.133 25.6155 104.471 16.8223 41.7205"
        stroke="#FFFFFF"
        strokeWidth="6"
        strokeLinejoin="round"
        strokeDasharray="2010"
        strokeDashoffset="2010"
        data-reversed="1"
      />
      <path
        d="M134 971C216.91 870.814 262.07 936.765 313.933 948.345C365.795 959.924 422.245 983.586 448 898L287 690.645C114.665 706.561 135.258 638.085 35 597L36 403C126 279 183.5 384 290 298L330.26 137.147C322.707 83.2507 249.083 99.0632 177.505 103.598C105.927 108.133 25.6155 104.471 16.8223 41.7205"
        stroke="#BFE0FA"
        strokeWidth="8"
        strokeLinejoin="round"
        strokeDasharray="12 12"
      />
      <path
        d="M448 898L287 690.645"
        stroke="#BFE0FA"
        strokeWidth="8"
        strokeLinejoin="round"
      />
      <path
        d="M35 597L36 403"
        stroke="#BFE0FA"
        strokeWidth="8"
        strokeLinejoin="round"
      />
      <path
        d="M290 298L330.26 137.147"
        stroke="#BFE0FA"
        strokeWidth="8"
        strokeLinejoin="round"
      />
      <circle
        cx="144"
        cy="960"
        r="15"
        fill="#FFFFFF"
        data-opacity-start="0.9"
        data-opacity-end="1"
        data-image-index="4"
        data-property="--tablet-value"
      />
      <circle
        cx="446"
        cy="900"
        r="15"
        fill="#FFFFFF"
        data-opacity-start="0.820"
        data-opacity-end="0.920"
        data-property="--tablet-value"
      />
      <circle
        cx="285"
        cy="696"
        r="15"
        fill="#FFFFFF"
        data-opacity-start="0.688"
        data-opacity-end="0.788"
        data-image-index="3"
        data-property="--tablet-value"
      />
      <circle
        cx="45"
        cy="598"
        r="15"
        fill="#FFFFFF"
        data-opacity-start="0.543"
        data-opacity-end="0.643"
        data-property="--tablet-value"
      />
      <circle
        cx="40"
        cy="405"
        r="15"
        fill="#FFFFFF"
        data-opacity-start="0.456"
        data-opacity-end="0.556"
        data-image-index="2"
        data-property="--tablet-value"
      />
      <circle
        cx="288"
        cy="300"
        r="15"
        fill="#FFFFFF"
        data-opacity-start="0.291"
        data-opacity-end="0.391"
        data-property="--tablet-value"
      />
      <circle
        cx="335"
        cy="133"
        r="15"
        fill="#FFFFFF"
        data-opacity-start="0.215"
        data-opacity-end="0.315"
        data-image-index="1"
        data-property="--tablet-value"
      />
      <circle
        cx="15"
        cy="35"
        r="15"
        fill="#FFFFFF"
        data-opacity-start="0"
        data-opacity-end="0"
        data-image-index="0"
        data-property="--tablet-value"
      />
    </svg>
  );
};

export default QualityLineTablet;
