import PropTypes from 'prop-types';
import YouTube from 'react-youtube';
import { useEffect, useState } from 'react';
import { preventModalClick, scrollToVideo, scrollToCaption } from 'helpers';
import MatroskinMessage from 'components/MatroskinMessage';
import { youtubeVideoID } from 'config';

import matroskin from 'assets/img/matroskin/matroskin-with-milk.png';
import scrollMobile from 'assets/img/scroll-mobile.svg';
import closeIcon from 'assets/img/matroskin/matroskin-close.svg';
import tatarstanBottleOne from 'assets/img/intro-tatarstan-bottle-1.png';
import tatarstanBottleTwo from 'assets/img/intro-tatarstan-bottle-2.png';
import tatarstanIcon from 'assets/img/intro-tatarstan-icon.png';
import matroskinClose from 'assets/img/matroskin/matroskin-close.svg';

const Intro = ({ currentTab, onStartVideo, onClose }) => {
  const [playerTarget, setPlayerTarget] = useState(false);

  useEffect(() => {
    if (currentTab === 'video') return;
    if (!playerTarget) return;

    playerTarget.pauseVideo();
  }, [currentTab]);

  const handleClick = (e) => {
    onClose();
  };

  const onPlay = () => {
    onStartVideo();
  };

  const onReady = ({ target }) => {
    setPlayerTarget(target);
  };

  return (
    <div className="intro intro--hidden-for-video" onClick={handleClick}>
      <div className="intro-content">
        <div className="intro-video" onClick={preventModalClick}>
          <YouTube
            className="intro-video-youtube"
            videoId={youtubeVideoID}
            onReady={onReady}
            onPlay={onPlay}
          />
        </div>
        <button className="intro-close" onClick={onClose}>
          <img src={closeIcon} alt="close-icon" />
        </button>
      </div>
    </div>
  );
};

Intro.propTypes = {
  onStartVideo: PropTypes.func,
  currentTab: PropTypes.string,
  onClose: PropTypes.func,
};

export default Intro;
