import PropTypes from 'prop-types';
import InfoModal from 'components/InfoModals/InfoModal';

import image1 from 'assets/img/info-modals/health.jpg';
import image2 from 'assets/img/info-modals/food.jpg';

const InfoModalsProvider = ({ id, onClick, onClose }) => {
  const InfoModals = {
    health: (
      <InfoModal
        blur={true}
        img={image1}
        title="Центр Здоровья"
        text="Мы ежедневно следим за показателями здоровья и поведения коров. Смотрим, чтобы они хорошо ели, были активными. Если видим отклонения — сразу измеряем температуру, делаем анализы. И отправляем на лечение, когда необходимо."
        description={`Александра Михайловна, <br /> ветеринарный врач`}
        onClick={onClick}
        onClose={onClose}
      />
    ),
    food: (
      <InfoModal
        blur={true}
        img={image2}
        title="Правильное питание"
        text="Чтобы получалось вкусное и качественное молоко, нужно корову кормить правильно: травами и злаками. Мы заботимся о питании наших коров и тщательно продумываем их рацион."
        description={`Андрей Александрович, <br /> главный зоотехник`}
        onClick={onClick}
        onClose={onClose}
      />
    ),
  };

  if (!id) return null;

  return InfoModals[id];
};

InfoModalsProvider.propTypes = {
  onClose: PropTypes.func,
  onClick: PropTypes.func,
  id: PropTypes.string,
};

export default InfoModalsProvider;
