import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { matroskinStore, panoramaStore } from 'store';
import { preventModalClick } from 'helpers';

import closeIcon from 'assets/img/matroskin/matroskin-close.svg';
import rotateIcon from 'assets/img/icon-rotate.svg';
import clickIcon from 'assets/img/icon-click.svg';

const PanoramaTutorial = observer(({ onClose }) => {
  const handleClose = () => {
    matroskinStore.setClickableState(true);
    matroskinStore.close();
    onClose();

    setTimeout(() => {
      const step = panoramaStore.currentPanorama.matroskinStep;
      matroskinStore.setCurrentStep(step);
    }, 300);
  };

  return (
    <div className="panorama-tutorial" onClick={handleClose}>
      <div className="panorama-tutorial-content" onClick={preventModalClick}>
        <div className="panorama-tutorial-step">
          <img src={rotateIcon} alt="rotate-icon" />
          <p>Вращай</p>
        </div>

        <div className="panorama-tutorial-step">
          <img src={clickIcon} alt="click-icon" />
          <p>Исследуй</p>
        </div>

        <button className="info-modal-button" onClick={handleClose}>
          Смотреть
        </button>

        <button className="info-modal-close" onClick={handleClose}>
          <img src={closeIcon} alt="close-icon" />
        </button>
      </div>
    </div>
  );
});

PanoramaTutorial.propTypes = {
  onClose: PropTypes.func,
};

export default PanoramaTutorial;
