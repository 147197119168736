import geolocation from 'assets/img/background/locations/desktop/geolocation.png';
import workers from 'assets/img/background/locations/desktop/workers.png';
import academy from 'assets/img/background/locations/desktop/academy.png';
import quality from 'assets/img/background/locations/desktop/quality.png';
import health from 'assets/img/background/locations/desktop/health.png';
import video from 'assets/img/background/locations/desktop/video.png';
import care from 'assets/img/background/locations/desktop/care.png';
import food from 'assets/img/background/locations/desktop/food.png';

import geolocationTablet from 'assets/img/background/locations/tablet/geolocation.png';
import workersTablet from 'assets/img/background/locations/tablet/workers.png';
import academyTablet from 'assets/img/background/locations/tablet/academy.png';
import qualityTablet from 'assets/img/background/locations/tablet/quality.png';
import healthTablet from 'assets/img/background/locations/tablet/health.png';
import videoTablet from 'assets/img/background/locations/tablet/video.png';
import careTablet from 'assets/img/background/locations/tablet/care.png';
import foodTablet from 'assets/img/background/locations/tablet/food.png';

import geolocationMobile from 'assets/img/background/locations/mobile/geolocation.png';
import workersMobile from 'assets/img/background/locations/mobile/workers.png';
import academyMobile from 'assets/img/background/locations/mobile/academy.png';
import qualityMobile from 'assets/img/background/locations/mobile/quality.png';
import healthMobile from 'assets/img/background/locations/mobile/health.png';
import videoMobile from 'assets/img/background/locations/mobile/video.png';
import careMobile from 'assets/img/background/locations/mobile/care.png';
import foodMobile from 'assets/img/background/locations/mobile/food.png';

export const mapSizes = {
  desktop: { width: 1920, height: 975 },
  tablet: { width: 1024, height: 1300 },
  mobile: { width: 320, height: 1809 },
};

export const locations = [
  {
    img: {
      desktop: care,
      tablet: careTablet,
      mobile: careMobile,
    },
    tab: 'care',
  },
  {
    img: {
      desktop: health,
      tablet: healthTablet,
      mobile: healthMobile,
    },
    tab: 'health',
  },
  {
    img: {
      desktop: geolocation,
      tablet: geolocationTablet,
      mobile: geolocationMobile,
    },
    tab: 'geolocation',
  },
  {
    img: {
      desktop: workers,
      tablet: workersTablet,
      mobile: workersMobile,
    },
    tab: 'workers',
  },
  {
    img: {
      desktop: academy,
      tablet: academyTablet,
      mobile: academyMobile,
    },
    tab: 'academy',
  },
  {
    img: {
      desktop: quality,
      tablet: qualityTablet,
      mobile: qualityMobile,
    },
    tab: 'quality',
  },
  {
    img: {
      desktop: video,
      tablet: videoTablet,
      mobile: videoMobile,
    },
    tab: 'video',
  },
  {
    img: {
      desktop: food,
      tablet: foodTablet,
      mobile: foodMobile,
    },
    tab: 'food',
  },
];
