import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { matroskinStore } from 'store';
import { setDataLayer } from '../../gtm';
import { preventModalClick } from 'helpers';

import { allCategories } from './CareGameData';

import closeIcon from 'assets/img/matroskin/matroskin-close.svg';
import houseIcon from 'assets/img/icon-house.svg';
import star from 'assets/img/care/star-icon.png';
import happyCow from 'assets/img/care/happy-cow.png';

const CareGame = ({ onClose }) => {
  const [categories, setCategories] = useState([]);
  const [categoryIndex, setCategoryIndex] = useState(0);
  const [answersCount, setAnswersCount] = useState(0);
  const [wrongCategoryIndex, setWrongCategoryIndex] = useState(null);
  const [chosenCategoryIndex, setChosenCategoryIndex] = useState(null);
  const [chosenOptionIndex, setChosenOptionIndex] = useState(null);
  const [optionsTimeout, setOptionsTimeout] = useState(null);
  const [gameOverState, setGameOverState] = useState(false);
  const minProgressValue = 0;

  const currentCategory = categories[categoryIndex];
  const progressValue = answersCount / categories.length;

  const restart = () => {
    setGameOverState(false);
    setChosenCategoryIndex(null);
    setChosenOptionIndex(null);

    setCategories([...allCategories].sort(() => 0.5 - Math.random()));
    setCategoryIndex(0);
    setAnswersCount(0);
    matroskinStore.setClickableState(true);
    matroskinStore.close();
    setTimeout(() => matroskinStore.setCurrentStep('care-1'), 300);
  };

  const openOptionsTab = (title, index) => {
    if (currentCategory.title !== title) {
      setWrongCategoryIndex(index);
      setTimeout(() => setWrongCategoryIndex(null), 700);
      return;
    }

    setChosenCategoryIndex(categoryIndex);
  };

  const closeOptionsTab = () => {
    setChosenCategoryIndex(null);
    setChosenOptionIndex(null);

    clearTimeout(optionsTimeout);
    setOptionsTimeout(null);

    if (answersCount !== categoryIndex) {
      setCategoryIndex(answersCount);
      if (answersCount === categories.length) {
        setGameOverState(true);
      }
    }
  };

  const onOptionClick = (option, index) => {
    clearTimeout(optionsTimeout);

    setChosenOptionIndex(index);

    if (option.correct) {
      const newAnswersCount = answersCount + 1;
      setAnswersCount(newAnswersCount);
      return;
    }

    setOptionsTimeout(setTimeout(() => setChosenOptionIndex(null), 700));
  };

  const getOptionClassName = (option, index) => {
    let className = '';

    if (chosenOptionIndex === null) return className;

    if (chosenOptionIndex === index) className += ' chosen';

    if (option.correct) {
      className += '-correct';
    } else {
      className += '-incorrect';
    }

    return className;
  };

  useEffect(() => {
    matroskinStore.setCurrentStep('care-1');
    matroskinStore.show();
    restart();
    matroskinStore.open();

    return () => {
      matroskinStore.setClickableState(true);
      matroskinStore.close();
    };
  }, []);

  useEffect(() => {
    matroskinStore.setClickableState(!gameOverState);

    if (gameOverState) {
      matroskinStore.setCurrentStep('care-2');
      matroskinStore.open();
      setDataLayer({
        event: 'GAEvent',
        eventCategory: 'show-result',
        eventAction: 'success',
        eventLabel: 'game-murka',
      });
    }
  }, [gameOverState]);

  const ResultTab = () => (
    <div className="care-game-content">
      <div className="care-game-content--result--blur"></div>
      <div className="care-game-content--result" onClick={onClose}>
        <div
          className="care-game-content--result-content"
          onClick={preventModalClick}
        >
          <h1 className="care-game-content--result-title">Мурка довольна</h1>
          <img
            className="care-game-content--result-image"
            src={happyCow}
            alt="happy-cow-image"
          />

          <button
            className="care-game-content--result-button"
            onClick={() => restart()}
          >
            Играть ещё
          </button>

          <button className="care-game-content--result-close" onClick={onClose}>
            <img src={closeIcon} alt="close-icon" />
          </button>
        </div>
      </div>
      <button className="care-game-content--button-home" onClick={onClose}>
        <img src={houseIcon} alt="house-icon" />
      </button>
    </div>
  );

  const OptionsTab = () => (
    <div className="care-game-overlay" onClick={closeOptionsTab}>
      <div className="care-game-content care-game-content--options">
        <div
          className="care-game-content--question"
          onClick={preventModalClick}
        >
          <h1
            dangerouslySetInnerHTML={{
              __html:
                chosenOptionIndex === null ||
                !currentCategory.options[chosenOptionIndex].correct
                  ? currentCategory.question
                  : currentCategory.description,
            }}
            className="care-game-content--question-title"
          />
          <div className="care-game-content--question-buttons">
            {currentCategory.options.map((option, index) => (
              <button
                key={index}
                disabled={chosenOptionIndex !== null}
                onClick={() => onOptionClick(option, index)}
                className={`care-game-content--question-button ${getOptionClassName(
                  option,
                  index
                )}`}
              >
                <img
                  className="care-game-content--categories-icon"
                  src={option.img}
                  alt="category-icon"
                />
                <p>{option.title}</p>
              </button>
            ))}
          </div>
          <button
            className="care-game-content--categories-close"
            onClick={() => closeOptionsTab(chosenOptionIndex)}
          >
            <img src={closeIcon} alt="close-icon" />
          </button>
        </div>

        <div className="care-game-content--progress">
          <progress max={1} value={progressValue || minProgressValue} />
          <div className="care-game-content--progress-line" />
          <div className="care-game-content--progress-line care-game-content--progress-line-gray" />
          <img
            style={{ left: `${(progressValue || minProgressValue) * 100}%` }}
            className="care-game-content--progress-star-icon"
            src={star}
            alt="star-icon"
          />
        </div>

        <button
          className="care-game-content--button-home"
          onClick={onClose}
          disabled
        >
          <img src={houseIcon} alt="house-icon" />
        </button>
      </div>
    </div>
  );

  const MainTab = () => (
    <div className="care-game-content" onClick={preventModalClick}>
      <div className="care-game-content--progress">
        <progress max={1} value={progressValue || minProgressValue} />
        <div className="care-game-content--progress-line" />
        <div className="care-game-content--progress-line care-game-content--progress-line-gray" />
        <img
          style={{ left: `${(progressValue || minProgressValue) * 100}%` }}
          className="care-game-content--progress-star-icon"
          src={star}
          alt="star-icon"
        />
      </div>

      <img
        className="care-game-content-cow"
        src={currentCategory?.img}
        alt="cow-img"
      />

      <div className="care-game-content--categories">
        {allCategories.map((category, index) => (
          <button
            onClick={() => openOptionsTab(category.title, index)}
            className={`care-game-content--categories-button care-game-content--categories-button-${index} ${
              wrongCategoryIndex === index ? 'incorrect' : ''
            }`}
            key={index}
            disabled={wrongCategoryIndex || wrongCategoryIndex === 0}
          >
            <img
              className="care-game-content--categories-icon"
              src={category.cowImg}
              alt="category-icon"
            />
            <p className="care-game-content--categories-title">
              {category.title}
            </p>
          </button>
        ))}
      </div>

      <button className="care-game-content--button-home" onClick={onClose}>
        <img src={houseIcon} alt="house-icon" />
      </button>
    </div>
  );

  if (gameOverState) {
    return <ResultTab />;
  }

  if (chosenCategoryIndex !== null) {
    return <OptionsTab />;
  }

  return <MainTab />;
};

CareGame.propTypes = {
  onClose: PropTypes.func,
};

export default CareGame;
