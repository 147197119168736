import region1 from 'assets/img/geolocation/region-1.jpg';
import region2 from 'assets/img/geolocation/region-2.jpg';
import region3 from 'assets/img/geolocation/region-3.jpg';
import region4 from 'assets/img/geolocation/region-4.jpg';
import region5 from 'assets/img/geolocation/region-5.jpg';
import region6 from 'assets/img/geolocation/region-6.jpg';

export const regions = [
  {
    id: 1,
    farmsCount: 71,
    img: region1,
    name: 'Центральный федеральный округ',
    shortName: 'Центральный ФО',
    regions: [
      'Белгородская область',
      'Брянская область',
      'Владимирская область',
      'Воронежская область',
      'Ивановская область',
      'Калужская область',
      'Костромская область',
      'Курская область',
      'Липецкая область',
      'Московская область',
      'Орловская область',
      'Рязанская область',
      'Смоленская область',
      'Тамбовская область',
      'Тверская область',
      'Тульская область',
      'Ярославская область',
      'Москва',
    ],
  },
  {
    id: 2,
    farmsCount: 24,
    img: region2,
    name: 'Северо-Западный федеральный округ',
    shortName: 'Северо-Западный ФО',
    regions: [
      'Республика Карелия',
      'Республика Коми',
      'Архангельская область',
      'Ненецкий автономный округ',
      'Вологодская область',
      'Калининградская область',
      'Ленинградская область',
      'Мурманская область',
      'Новгородская область',
      'Псковская область',
      'Санкт-Петербург',
    ],
  },
  {
    id: 3,
    farmsCount: 7,
    img: region3,
    name: 'Южный федеральный округ',
    shortName: 'Южный ФО',
    regions: [
      'Республика Адыгея',
      'Астраханская область',
      'Волгоградская область',
      'Краснодарский край',
      'Ростовская область',
      'Мурманская область',
    ],
  },
  {
    id: 4,
    farmsCount: 7,
    img: region3,
    name: 'Северо-Кавказский федеральный округ',
    shortName: 'Северо-Кавказский ФО',
    regions: [
      'Республика Дагестан',
      'Республика Ингушетия',
      'Кабардино-Балкарская республика',
      'Карачаево-Черкесская республика',
      'Республика Северная Осетия — Алания',
      'Ставропольский край',
      'Чеченская республика',
    ],
  },
  {
    id: 5,
    farmsCount: 44,
    img: region4,
    name: 'Приволжский федеральный округ',
    shortName: 'Приволжский ФО',
    regions: [
      'Республика Башкортостан',
      'Республика Марий Эл',
      'Республика Мордовия',
      'Республика Татарстан',
      'Удмурсткая республика',
      'Чувашская республика',
      'Кировская область',
      'Нижегородская область',
      'Оренбургская область',
      'Пензенская область',
      'Пермская область',
      'Самарская область',
      'Саратовская область',
      'Ульяновская область',
    ],
  },
  {
    id: 6,
    farmsCount: 48,
    img: region5,
    name: 'Уральский федеральный округ',
    shortName: 'Уральский ФО',
    regions: [
      'Курганская область',
      'Свердловская область',
      'Тюменская область',
      'Челябинская область',
      'Ханты-Мансийский автономный округ',
      'Ямало-Ненецкий автономный округ',
    ],
  },
  {
    id: 7,
    farmsCount: 20,
    img: region6,
    name: 'Сибирский федеральный округ',
    shortName: 'Сибирский ФО',
    regions: [
      'Республика Алтай',
      'Республика Тыва',
      'Республика Хакасия',
      'Алтайский край',
      'Красноярский край',
      'Иркутская область',
      'Кемеровская область',
      'Новосибирская область',
      'Омская область',
      'Томская область',
    ],
  },
  {
    id: 8,
    farmsCount: 0,
    img: null,
    name: 'Дальневосточный федеральный округ ',
    shortName: 'Дальневосточный ФО',
    regions: [
      'Республика Бурятия',
      'Республика Саха (Якутия)',
      'Забайкальский край',
      'Камчатский край',
      'Приморский край',
      'Хабаровский край',
      'Амурская область',
      'Магаданская область',
      'Сахалинская область',
      'Еврейская автономная область',
      'Чукотский автономный округ',
    ],
  },
];
