import PropTypes from 'prop-types';
import { preventModalClick } from 'helpers';

import MatroskinMessage from 'components/MatroskinMessage';

import closeIcon from 'assets/img/matroskin/matroskin-close.svg';
import matroskin from 'assets/img/matroskin/matroskin.png';
import cow from 'assets/img/care/care-cow.png';

const CareTutorial = ({ onClick, onClose }) => {
  return (
    <div className="care-tab-tutorial" onClick={onClick}>
      <div className="care-tab-content" onClick={preventModalClick}>
        <h1 className="care-tab-tutorial-title">
          Поухаживай
          <br className="mobile" /> за муркой
        </h1>
        <img className="care-tab-tutorial-image" src={cow} alt="cow-img" />
        <button className="care-tab-close" onClick={onClose}>
          <img src={closeIcon} alt="close-icon" />
        </button>
        <button className="care-tab-button" onClick={onClick}>
          Играть
        </button>
        <div className="care-tab-tutorial-matroskin">
          <img src={matroskin} alt="matroskin" />
        </div>
        <MatroskinMessage
          text={`Чтобы молочко вкусным было, надо о бурёнках хорошо заботиться. Попробуйте и вы!`}
        />
      </div>
    </div>
  );
};

CareTutorial.propTypes = {
  onClick: PropTypes.func,
  onClose: PropTypes.func,
};

export default CareTutorial;
