import { observer } from 'mobx-react';
import { loadingStore } from 'store';

const Loader = observer(() => {
  return (
    <div className={`loader ${loadingStore.loading ? '' : 'loader--hidden'}`}>
      <p>Загрузка</p>
      <span>.</span>
      <span>.</span>
      <span>.</span>
    </div>
  );
});

export default Loader;
