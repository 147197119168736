import image0 from 'assets/img/matroskin/image-0.jpg';
import image1 from 'assets/img/matroskin/image-1.jpg';

export const matroskinData = {
  intro: {
    afkTime: 25,
    image: image0,
    image2: image1,
    text: 'Жмите скорее на домики <br class="mobile" /> или вывески, чтобы начать экскурсию по нашей ферме!',
    text2: 'Приз уже ждет вас в Молочной Академии!',
  },
  map: {
    afkTime: 120,
    image: null,
    text: 'Наконец-то вы к нам в <br /> <br class="mobile" /> гости зашли! <br class="mobile" /> Посмотрите, <br /> как у нас <br class="mobile" /> тут всё устроено.',
  },
  academy: {
    afkTime: 20,
    image: null,
    text: 'А вы уже посмотрели <br class="mobile" /> кино <br /> про нашу ферму? <br class="mobile" /> Сейчас <br /> самое время!',
  },
  quality: {
    afkTime: 120,
    image: null,
    text: 'Забота на каждом этапе — <br /> <br class="mobile" /> моя школа!',
  },
  workers: {
    afkTime: 20,
    image: null,
    text: 'А вот и специалисты <br class="mobile" /> с <br /> ферм наших. Всё о <br class="mobile" /> наших <br /> коровках и <br class="mobile" /> молоке знают.',
  },
  health: {
    afkTime: 30,
    image: null,
    text: 'На нашей ферме коровки <br /> тщательную проверку <br /> проходят!',
  },
  food: {
    afkTime: 30,
    image: null,
    text: 'Вот они, мои <br class="mobile" /> трудолюбивые <br /> красавицы! <br class="mobile" /> Правильно <br /> питаются, <br class="mobile" /> чтобы потом <br /> молоко <br class="mobile" /> правильное давать.',
  },
  'food-1': {
    afkTime: 30,
    image: null,
    text: 'Сбалансированная <br class="mobile" /> диета! <br /> Такое и нашей <br class="mobile" /> маме <br /> понравится.',
  },
  'care-1': {
    afkTime: Infinity,
    image: null,
    text: 'Угадайте, как за Муркой поухаживать. Подскажут возникающие над ней <br /> иконки. Вот она <br class="mobile" /> довольная будет!',
  },
  'care-2': {
    afkTime: Infinity,
    image: null,
    text: 'Вы отлично справились! Мурка не нарадуется.',
  },
  'panorama-tutorial': {
    afkTime: 30,
    image: null,
    text: 'Ферму со всех сторон <br /> <br class="mobile" /> изучить можно! А под <br /> <br class="mobile" /> синими кнопками — <br class="mobile" /> факты <br /> интересные, сам <br class="mobile" /> проверял.',
  },
  'geolocation-1': {
    afkTime: 30,
    image: null,
    text: 'В Простоквашино 230* <br /> <br class="mobile" /> ферм по всем стране. <br /> <br class="mobile" /> Хотим рядом с вами наши <br /> <br class="mobile" /> фермы показать!',
  },
  'geolocation-2': {
    afkTime: 30,
    image: null,
    text: 'Ко мне ближе всех <br class="mobile" /> Мурка <br />  моя! А вы <br class="mobile" /> откуда?',
  },
};
