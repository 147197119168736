import { makeAutoObservable } from 'mobx';
import { matroskinData as messages } from 'enums/matroskinData';
import { defaultAfkTime } from 'config';

class MatroskinStore {
  clickable = true;

  visible = false;

  active = false;

  currentStep = 'intro';

  interval = null;

  afkTime = 1;

  constructor() {
    makeAutoObservable(this);

    document.addEventListener('mousedown', this.onMouseDown.bind(this));
    document.addEventListener('touchstart', this.onMouseDown.bind(this));

    document.addEventListener('mouseup', this.onMouseUp.bind(this));
    document.addEventListener('touchend', this.onMouseUp.bind(this));
  }

  get className() {
    let className = 'matroskin-overlay';

    if (!this.visible) className += ' matroskin-overlay--hidden';
    if (!this.clickable) className += ' matroskin-overlay--non-clickable';
    if (this.active) className += ' matroskin-overlay--active';

    return className;
  }

  get message() {
    return messages[this.currentStep]?.text;
  }

  get message2() {
    return messages[this.currentStep]?.text2;
  }

  get image() {
    return messages[this.currentStep]?.image;
  }

  get image2() {
    return messages[this.currentStep]?.image2;
  }

  get stepAfkTime() {
    return messages[this.currentStep]?.afkTime || defaultAfkTime;
  }

  show() {
    this.visible = true;
  }

  hide() {
    this.visible = false;
  }

  open() {
    this.active = true;
  }

  close() {
    this.active = false;

    if (this.currentStep !== 'intro') return;

    setTimeout(() => this.setCurrentStep('map'), 300);
  }

  toggle() {
    if (this.active) {
      return this.close();
    }
    return this.open();
  }

  setCurrentStep(step) {
    this.currentStep = step;
  }

  setClickableState(clickable) {
    this.clickable = clickable;
  }

  clearInterval() {
    clearInterval(this.interval);
    this.interval = null;
    this.afkTime = 1;
  }

  intervalTick() {
    if (this.afkTime < this.stepAfkTime) {
      this.afkTime += 1;
      return;
    }
    this.clearInterval();

    if (!this.visible) return;

    this.open();
  }

  onMouseDown() {
    this.clearInterval();
  }

  onMouseUp() {
    this.clearInterval();
    this.interval = setInterval(this.intervalTick.bind(this), 1000);
  }
}

export default new MatroskinStore();
